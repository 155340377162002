@media all and (max-width: 740px) {
	.career-top{
		.left-leaf{display: none;}
	}
	.box-career{
		.title-career{
			width: 100%;
			h3{font-size: 20px;}
		}
	}
	.career-list{
		.col{
			flex-wrap: wrap;position: relative;padding: 20px 60px 20px 20px;
			.text-career,.right-career{width: 100%;}
			.text-career{
				margin-bottom: 5px;
				padding-right: 0px; 
			}
			.right-career{
				position: static;
				.arrow{
					right: 20px;	
				}
			}
			&:hover{
				.right-career{
					color: #292929;
					.arrow{
						&:before{
							/* top:-80px;right: -50px;
							@include transform(scale(1)); */
							top:0; margin-top: 0; 
							@include transform(scale(1));
						}
					}
				}
			}
		}
	}
	.career-detail{
		h3{font-size: 20px;margin-bottom: 10px}
		.foot-career{
			margin-top: 40px;
			.right{line-height: 50px;}
		}
	}
	.input_file{
		&:after{
			content:"Browse";text-align: center;line-height: 50px;
			background: #b7b4a2;@include borderRadius(10px);
			color: #333;font-size: 12px;width: 70px;
		}
		span{
			padding-right: 60px;word-wrap: break-word;max-height: 50px;
			overflow: hidden;display: block;
		}
	}
}
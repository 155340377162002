@media all and (max-width: 1300px) {
	.detail-page{
		.ttl-detail{
			h2{font-size: 30px;line-height: 40px;margin-bottom: 30px;}
		}
	}
}

@media all and (max-width: 1020px) {
	.detail-page{
		.ttl-detail{
			h2{font-size: 24px;line-height: 32px;}
		}
	}
}
.slider-promo{
	.list{
		position: relative;
		&:before{
			content:"";@include position(absolute, 0px 0px 0px 0px);background: rgba(41,41,41,.8);
			@include transition(.3s all ease-out);
		}
		img{width: 100%;}

		&.slick-active{
			&:before{opacity:0;}
		}
	}
	.slick-arrow{
		position: absolute;z-index: 2;top: 50%;width: 66px;height: 66px;@include borderRadius(100%);
		font-size: 0px;color: transparent;padding: 0px;border:none;cursor: pointer;margin-top: -33px;
		background: rgba(255,255,255,.2) url('../images/material/arr-long.svg')no-repeat center;
		&:before{
			content:"";@include position(absolute, 0px 0px 0px 0px);background: rgba(255,255,255,.4);
			@include borderRadius(100%);@include transform(scale(.5));@include transition(.2s all ease-out);
			opacity: 0;
		}
		&.slick-prev{left: 70px;}
		&.slick-next{right: 70px;@include transform(rotate(180deg));}

		&:hover{
			&:before{@include transform(scale(1));opacity: 1;}
		}
	}
}

.promobox{
	h3{font-size: 31px;margin-bottom: 40px;}
}


.promo-lg{
	.list{
		display: none;
		&.active{display: block;}
	}
}

.partner-link{
	display: flex;flex-wrap: wrap;align-items: center;justify-content: center;
	a{display: inline-block;margin: 0px 20px 35px;}
}

.health-detail{
	position: relative;overflow: hidden;
	h2{
		/*font-size: 81px;*/
		/*line-height: 81px;*/
		font-size: 60px;
		line-height: 1;
		color: #4B4833;margin-bottom: 40px;
	}
	blockquote{font-size: 18px;line-height: 32px;font-style: italic;font-weight: 300;color: #7C7968;}
	.txbg-large{
		font-family: $sunset;font-size: 180px;color: #4D4C44;font-weight: 700;
		white-space: nowrap;opacity: .04;@include position(absolute, 77px null null 0px);
	}
	.breadcrumbs{margin-bottom: 20px;}
}

.box-slidermagazine{
	background: url(../images/material/paper_fibers.png) repeat top left;padding: 74px 0px 141px;
	h3{font-size: 31px;margin-bottom: 46px;}
}

.slidermagazinedetail{
	position: relative;margin-right: -400px;
	.slick-track{display: flex;}
	.list{
		display: block;margin-right: 30px;width: 270px;background: $white;@include borderRadius(10px);
		height: auto;
		figure{
			position: relative;overflow: hidden;@include borderRadius(10px 10px 0px 0px);
			&:before{
				content:"";position: absolute;top: 0px;left: 0px;bottom: 0px;right: 0px;background: rgba(0,0,0,0.5);
				opacity: 0;@include transition(.2s all ease-out);z-index: 1;
			}
			img{@include transition(.2s all ease-out);}
		}
		figcaption{
			padding: 20px;@include borderRadius(0px 0px 10px 10px);color: #4D4C44;
			font-size: 15px;line-height: 20px;
		}

		&:hover{
			figure{
				&:before{opacity:1;}
				img{@include transform(scale(1.1));filter: blur(3px);-webkit-filter: blur(2px);}
			}
		}
	}
	.slick-arrow{
		position: absolute;top: 50%;border: none;padding: 0px;font-size: 0px;color: transparent;width: 50px;
		height: 50px;background:rgba(41,41,41,.05) url('../images/material/arr-long-black.svg')no-repeat center;cursor: pointer;
		@include borderRadius(100%);z-index: 1;left: -87px;background-size: 22px;
		&.slick-prev{margin-top: -68px;}
		&.slick-next{@include transform(rotate(180deg));}
	}
}

.program-section{
	padding: 101px 0px 117px;
	.ttl-program{
		text-align: center;width: 520px;margin: 0px auto 58px;
		h3{margin-bottom: 5px;}
		p{font-family: $opensans;font-size: 16px;line-height: 24px;color: #7C7968;}
	}
}

.event-list{
	display: flex;flex-wrap: wrap;margin:0px -15px 30px;
	.list{
		display: block;margin:0px 15px 35px;
		flex: 1 0 calc(25% - 30px);max-width: calc(25% - 30px);
		@include boxShadow(0px 0px 5px 0px rgba(0,0,0,0.2));
		@include borderRadius(10px);
		figure{
			position: relative;
			&:before{
				content:"";position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;background: rgba(0,0,0,.3);
				@include borderRadius(10px 10px 0px 0px);opacity: 0;@include transition(.3s all ease-out);
			}
			img{width: 100%;@include borderRadius(10px 10px 0px 0px);}
			.date-inlist{
				position: absolute;top: 0px;left: 0px;padding: 10px;background: #1F5B29;color: $white;
				text-align: center;@include borderRadius(10px 0px 0px 0px);z-index: 2;
				h4{font-size: 26px;font-family: $sfui;line-height: 30px;}
				span{font-size: 10px;text-transform: uppercase;}
			}
			.tilted{
				@include position(absolute, null 20px 30px null);color: $white;@include transform(rotate(-15deg));
				font-size: 24px;font-weight: 700;font-family: $sunset;text-align: center;
				span{display: block;line-height: 20px;}
				b{font-size: 40px;line-height: 24px;}
			}
		}
		figcaption{
			padding: 20px;
			h5{font-weight: 600;font-size: 16px;color: #4D4C44;font-family: $sfui;line-height: 20px;}
		}

		&:hover{
			figure{
				&:before{opacity: 1;}
			}
		}
	}
}

.pop-gallery-list{
	.list-pop{
		display: none;
		&.active{display: block;}
	}
	.numslide{
		position: absolute;top: 50%;right: 0px;color: $white;font-size: 20px;padding: 15px 20px 56px;
		font-family: $eczar;background: #698D48;@include borderRadius(10px 0px 0px 10px);width: 150px;
		margin-top: -30px;
		display:none;
		@include boxSizing(border-box);text-align: center;
		.num{font-size: 31px;font-weight: 700;}
	}
}

#popup_gallery .in-popup .overflow-box{
    margin: 0 -120px;
	padding: 0 120px;

}

.slidergallery{
	position: relative;
	.slick-arrow{
		position: absolute;top: 50%;border: none;padding: 0px;font-size: 0px;color: transparent;width: 50px;
		height: 50px;background:#698D48 url('../images/material/arr-long.svg')no-repeat center;cursor: pointer;
		z-index: 1;
		border-radius:50px;
		/*margin-top: 45px;*/
		margin-top: 15px;
		&.slick-prev{left: -120px;}
		&.slick-next{@include transform(rotate(180deg));right: -120px;}
	}
}

.past-issues{
	h3{font-family: $eczar;font-size: 31px;color: #4D4C44;}
}

.ttl-mailer{
	margin-bottom: 47px;
	h3{font-size: 31px;color: #4D4C44;}
}
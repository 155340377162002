.career-top{
    .left-leaf{top: 300px;}
}
.box-career{
    .title-career{
        text-align: center; width: 520px; margin: 0 auto; margin-bottom: 40px;
        h3{font-size: 39px; line-height: 54px;font-weight: 700; color: #4D4C44; font-family: $sunset;}
        p{font-family: $opensans; font-size: 16px; line-height: 24px; color: #7C7968;}
    }
 }
 .career-list{
    .col{
        margin-bottom: 5px;@include borderRadius(10px);background: $white;position: relative;
        padding: 20px 30px 20px 30px;display: flex;justify-content: space-between;align-items: center;
        overflow: hidden;
        &:before{
            content:"";@include position(absolute, 50% -100px null null);width: 100px;height: 100px;
            background: #7CA557;@include borderRadius(50px);margin-top: -50px;
            @include transition(.3s all $ease-in-out);
        }
        .text-career{
            position: relative;z-index: 2; 
            width: 100%; padding-right: 200px;  
            box-sizing: border-box; 
            span,h5{text-transform: uppercase;@include transition(.3s all $ease-in-out);}
            span{font-size: 13px;line-height: 18px;color: #7C7968;}
            h5{font-family: $sfui; font-size: 16px;line-height: 24px;color: #292929;} 
        }
        .right-career{
            font-size: 13px;font-weight: 500;color: #698D48;position: absolute;padding-right: 50px;
            z-index: 1;@include transition(.3s all $ease-in-out);
            top: 0; right: 0; 
            height: 100%; 
            display: flex;
            align-items: center; 
            .arrow{
                position: absolute;top: 50%;right: 0px;width: 30px;height: 30px;display: block;
                z-index: 1;margin: -15px 0px 0px -15px;z-index: -1;
                &:before{
                    content:"";width:100%;height: 100%;margin: auto;background: #7CA557;@include borderRadius(100%);
                    z-index: -1;@include transition(.4s all $ease-in-out);
                    @include position(absolute, 0px 0px 0px 0px);
                }
                &:after{
                    content:"";position: absolute;width:100%;height: 100%;margin: auto;
                    background: url('../images/material/arrow-right-career.svg')no-repeat center;
                    @include transform(rotate(-90deg));
                }
            }
        }

        &:hover{
            /*&:before{width: 120%;}*/
            /*.text-career{
                span,h5{color: $white;}
            }*/
            .right-career{
                color: $white;
                .arrow{
                    &:before{@include transform(scale(14));top: -200px;}
                }
            }
        }

        &:last-child{margin-bottom: 0;}
    }
}
 .no-banner{
     margin-top: 250px;
 }
 .date-content{
    margin: 0px 0px 20px; padding: 0; color: #698D48; display: inline-block; font-size: 13px; font-weight: 600;
    font-family: $sfui;line-height: 18px; padding-right: 25px; margin-right: 15px; box-sizing: border-box;
    span{
        display: inline-block;margin: 0px 10px;
        &:first-child{margin-left:0px;}
        &:last-child{margin-right:0px;}
    }
}
.career-detail{
    h3{margin-bottom: 30px;font-size: 49px;}
    h5{color: #7C7968;font-family: $sfui;font-size: 16px;}
    .link{font-family: $sfui; font-size: 14px; color: #7CA557; font-weight: 600; line-height: 50px;}
    .btn-submit:hover{color: #fff;}
    .foot-career{
        margin-top: 80px; margin-bottom: 60px;
        border-top: 1px solid #EEEEEE;
        padding-top: 22px;
    }
}
.input_file{
    display: block; @include boxSizing(border-box); 
    width: 86% !important; 
    position: relative;
    height: 50px !important;
    input[type='file'] {
        width: 100%; @include boxSizing(border-box); z-index: 4;
        &.error {
            + label {
                + span {
                    + small {border-color: #f2685d;}
                }
            }
            + span {
                + small {border-color: #f2685d;}
            }
        }
    }
    span{
        font-size: 16px;color: rgba(41,41,41,.3); line-height: 50px; padding-left: 15px;
        position: absolute;
        width: 100%; 
        box-sizing: border-box; 
    }
    @include andplaceholder{color: rgba(41,41,41,.3);}
    &:after{ 
        content: ''; background: url('../images/material/upload.png')no-repeat right 0; 
        position: absolute; 
        right: -20px; 
        top: 50%; 
        transform: translateY(-50%); width: 110px; height: 50px; color: #f00;
    }
}
.box-form{
    .col{
        select{-moz-appearance: none; -webkit-appearance: none; appearance: none; background: url(../images/material/arr-down.png);background-repeat: no-repeat, repeat; background-position: right .7em top 50%, 0 0;}    
    }
}
// .select{ font-size: 14px; line-height: 17px; min-width: 120px; box-sizing: border-box; margin: 0; padding: 8px 12px; -moz-appearance: none; -webkit-appearance: none; appearance: none; background: url(../images/material/arr-down.png);background-repeat: no-repeat, repeat; background-position: right .7em top 50%, 0 0; cursor: pointer;}
.trust-top{
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.left-trust{
		width: 59%;
		display: flex;
		justify-content: flex-end;
		.box-img-apps{margin-top: 20px;}
		.in-left-trust{width:552px;}
	}
	.right-trust{
		width: 35%;
		figure{
			position: relative;
			img{width: 100%;@include borderRadius(30px 0px 0px 30px);}
		}
	}
}

.trust-point{
	display: flex;justify-content: center;flex-wrap: wrap;margin: 0px -10px;
	.list{
		margin:0px 10px 20px;flex: 1 0 calc(25% - 20px);max-width: calc(25% - 20px);
		img{display: block;margin-bottom: 17px;}
		h5{font-size: 18px;color: #4D4C44;margin-bottom: 16px;}
		p{font-size: 16px;line-height: 28px;color: #7C7968;}
	}
}
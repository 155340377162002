.box-product{
	background: url('../images/material/paper_fibers.png')repeat left top;padding: 100px 0px;
	position: relative;
}
.product-list{
	.list{
		display: flex;justify-content: space-between;margin-bottom: 70px;align-items: center;
		figure{
			width: 400px;
			img{width: 100%;@include borderRadius(10px);}
		}
		figcaption{
			width: 500px;color: #7C7968;
			h3{color: #4D4C44;margin-bottom: 20px;}
			blockquote{
				padding: 0px;font-family: $opensans;font-size: 18px;line-height: 32px;font-style: italic;font-weight: 300;
				margin-bottom: 15px;
			}
			p{font-family: $opensans;font-size: 14px;line-height: 24px;}
		}

		&:nth-child(even){
			figure{order: 2;}
			figcaption{order: 1;}
		}
	}
}
.btn-shopnow{
	display: inline-block;line-height: 60px;padding: 0px 35px 0px 72px;color: $white;
	text-transform: uppercase;background: #b2161b;position: relative;z-index: 1;
	&:before{
		content:"";position: absolute;top: 50%;left: 33px;width: 30px;height: 30px;background: url('../images/material/ic-shownow.svg')no-repeat center;
		margin-top: -18px;
	}
	&:after{
		content:"";position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;background: #9e090e;
		opacity: 0;@include transform(scale(.6));z-index: -1;@include transition(.2s all ease-out);
	}

	&:hover{
		color:$white;
		&:after{opacity:1;@include transform(scale(1));}
	}
}

.btn-rounded{
	display: inline-block;line-height: 60px;height: 60px;text-transform: uppercase;padding: 0px 40px;
	background: #B93236;color: $white;@include borderRadius(60px);font-size: 19px;font-weight: 700;
	letter-spacing: .6px;position: relative;border: 2px solid transparent;@include transition(.2s all ease-out);
	&:before{
		content:"";position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;background: $white;
		z-index: -1;@include borderRadius(60px);border: 2px solid #B93236;@include transform(scale(0.8));
		@include transition(.2s all ease-out);opacity: 0;
	}
	&.btn-mid{line-height: 50px;height: 50px;font-size: 16px;}

	&:hover{
		color:$white;background: transparent;color: #B93236;
		&:before{@include transform(scale(1));opacity: 1;}
	}
}

.btn-submit{
	display: inline-block;height: 60px;line-height: 60px;padding: 0px 40px;background: #1F5B29;color: $white;
	border: none;font-size: 16px;font-weight: 700;@include borderRadius(10px);cursor: pointer;position: relative;
	z-index: 1;
	&:before{
		content:"";position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;background: #143A1B;
		@include borderRadius(10px);z-index: -1;opacity: 0;@include transform(scale(.6));
		@include transition(.2s all ease-out);
	}

	&:hover{
		color: $white;
		&:before{opacity:1;@include transform(scale(1));}
	}
}

.link-inline{
	display: inline-block;font-weight: 700;letter-spacing: 3px;font-size: 16px;line-height: 24px;color: #8DB766;position: relative;
	text-transform: uppercase;
	&:before{content:"";position: absolute;bottom: 0px;left: 0px;height: 2px;background: #8DB766;width: 0px;@include transition(.2s all ease-out);}
	&:hover{
		color: #8DB766;
		&:before{width: 100%;}
	}

	&.nocapital{text-transform: inherit;letter-spacing: 0px;}
	&.red{
		color: #B2161B;
		&:before{background:#B2161B;}
	}
}

.link-arrow{
	display: inline-block;font-size: 13px;font-weight: 600;color: #698D48;text-transform: uppercase;
	letter-spacing: 1px;position: relative;padding-right: 47px;line-height: 30px;z-index: 1;@include transition(.2s all ease-out);
	&:before{
		content:"";position: absolute;top: 0px;right: 0px;bottom: 0px;height: 30px;width: 30px;margin:auto;
		background: #8DB766;@include borderRadius(30px);z-index: -1;@include transition(.2s all ease-out);
	}
	&:after{
		content:"";position: absolute;top: 0px;right: 12px;bottom: 0px;height: 30px;width: 30px;margin:auto;
		background: url('../images/material/arr-link-right.svg')no-repeat center;@include transition(.2s all ease-out);
	}

	&:hover{
		padding-left: 15px;color: $white;
		&:before{width:100%;}
		&:after{right: 10px;}
	}
}

.gra-green{
	background: rgba(31,91,41,1);
	background: -moz-linear-gradient(top, rgba(31,91,41,1) 0%, rgba(44,116,56,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(31,91,41,1)), color-stop(100%, rgba(44,116,56,1)));
	background: -webkit-linear-gradient(top, rgba(31,91,41,1) 0%, rgba(44,116,56,1) 100%);
	background: -o-linear-gradient(top, rgba(31,91,41,1) 0%, rgba(44,116,56,1) 100%);
	background: -ms-linear-gradient(top, rgba(31,91,41,1) 0%, rgba(44,116,56,1) 100%);
	background: linear-gradient(to bottom, rgba(31,91,41,1) 0%, rgba(44,116,56,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f5b29', endColorstr='#2c7438', GradientType=0 );
}
.gra-transblack{
	background: rgba(0,0,0,0);
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.42) 34%, rgba(0,0,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(34%, rgba(0,0,0,0.42)), color-stop(100%, rgba(0,0,0,1)));
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.42) 34%, rgba(0,0,0,1) 100%);
	background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.42) 34%, rgba(0,0,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.42) 34%, rgba(0,0,0,1) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.42) 34%, rgba(0,0,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}
.gra-blackwhite{
	background: rgba(41,41,41,0);
	background: -moz-linear-gradient(top, rgba(41,41,41,0) 0%, rgba(41,41,41,1) 84%, rgba(41,41,41,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(41,41,41,0)), color-stop(84%, rgba(41,41,41,1)), color-stop(100%, rgba(41,41,41,1)));
	background: -webkit-linear-gradient(top, rgba(41,41,41,0) 0%, rgba(41,41,41,1) 84%, rgba(41,41,41,1) 100%);
	background: -o-linear-gradient(top, rgba(41,41,41,0) 0%, rgba(41,41,41,1) 84%, rgba(41,41,41,1) 100%);
	background: -ms-linear-gradient(top, rgba(41,41,41,0) 0%, rgba(41,41,41,1) 84%, rgba(41,41,41,1) 100%);
	background: linear-gradient(to bottom, rgba(41,41,41,0) 0%, rgba(41,41,41,1) 84%, rgba(41,41,41,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#292929', endColorstr='#292929', GradientType=0 );
}
.gra-fullblackwhite{
	background: rgba(0,0,0,0);
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,1)));
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}

.sc-top{margin-top: 160px;}

.side-title{
	display: flex;justify-content: space-between;align-items:baseline;margin-bottom: 28px;
	> h3{margin-bottom: 0px;width: 75%;}
}

.thumb-img{
	display: flex;justify-content: space-between;
	.list{
		display: block;background: $white;@include borderRadius(10px);width: 280px;
		figure{
			position: relative;overflow: hidden;@include borderRadius(10px 10px 0px 0px);
			&:before{
				content:"";position: absolute;top: 0px;left: 0px;bottom: 0px;right: 0px;background: rgba(0,0,0,0.5);
				opacity: 0;@include transition(.2s all ease-out);z-index: 1;
			}
			img{
				width: 100%; 
				@include transition(.2s all ease-out);
			}
		}
		figcaption{
			padding: 20px 30px;font-size: 16px;color: #4D4C44;
			p{margin-bottom: 0px;}
		}

		&:hover{
			figure{
				&:before{opacity:1;}
				img{@include transform(scale(1.1));filter: blur(3px);-webkit-filter: blur(2px);}
			}
		}
	}
}

.side-thumb{
	display: flex;justify-content: space-between;
	.ls-thumb{
		width: 48%;display: flex;justify-content: space-between;
		figure{
			width: 48%;
			img{@include borderRadius(10px);@include boxShadow(0px 20px 99px 0px rgba(0,0,0,0.06));width: 100%;}
		}
		figcaption{
			width: 47%;padding-top: 5px;
			span{display: block;font-size: 13px;color: #A3A3A3;font-weight: 600;margin-bottom: 13px;}
			p{font-size: 16px;line-height: 30px;color: #555555;margin-bottom: 11px;}

		}
	}

	&.fullwidth{
		display: block;justify-content: inherit;margin-bottom: 80px;
		.ls-thumb{
			width: 100%;margin-bottom: 38px;align-items: center;
			figure{width: 370px;}
			figcaption{
				width: 570px;
				span{color: #698D48;font-size: 13px;font-weight: 500;}
				h5{font-size: 18px;line-height: 28px;font-family: $sfui;margin-bottom: 5px;}
				p{font-size: 14px;line-height: 24px;color: #7C7968;}
			}
		}
	}
}

.bg-bot-img{
	z-index: -1; 
	width: 100%; height: 55px;  
	left: 0; bottom: 0;  
	position: absolute; 
	&:before{
		content:"";position: absolute;bottom: -76px;left: -52px;width: 270px;height: 235px;
		background: url('../images/material/bg-left-bottom.png')no-repeat top right;
	}
	&:after{
		content:"";position: absolute;bottom: -151px;right: -45px;width: 386px;height: 366px;
		background: url('../images/material/bg-right-bottom.png')no-repeat top left;
	}
}

.bg-brush{background: url(../images/material/paper_fibers.png) repeat top left;}

.banner{
	position: relative;@include boxShadow(0px 20px 99px 0px rgba(0,0,0,.06));
	figure{
		position: relative;overflow: hidden;
		&:before{
			content:"";position: absolute;top: 0px;left: 0px;bottom: 0px;right: 0px;opacity: .6;
			background: rgba(41,41,41,1);
			background: -moz-linear-gradient(left, rgba(41,41,41,1) 0%, rgba(41,41,41,0) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(41,41,41,1)), color-stop(100%, rgba(41,41,41,0)));
			background: -webkit-linear-gradient(left, rgba(41,41,41,1) 0%, rgba(41,41,41,0) 100%);
			background: -o-linear-gradient(left, rgba(41,41,41,1) 0%, rgba(41,41,41,0) 100%);
			background: -ms-linear-gradient(left, rgba(41,41,41,1) 0%, rgba(41,41,41,0) 100%);
			background: linear-gradient(to right, rgba(41,41,41,1) 0%, rgba(41,41,41,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#292929', endColorstr='#292929', GradientType=1 );
		}
		img{width: 100%;}
	}
	figcaption{
		position: absolute;top: 50%;left: 0px;right: 0px;width: 990px;margin: 0px auto;
		@include transform(translateY(-50%));color: $white;
		h2{font-size: 39px;line-height: 1.2;}
		.breadcrumbs{margin-bottom: 10px;}
	}
}

.breadcrumbs{
	li{
		display: inline-block;font-size: 12px;font-weight: 600;margin-right: 38px;position: relative;
		&:before{
			content:"";position: absolute;top: 50%;left: 100%;margin-left: 10px;height: 1px;background: #B7B4A2;
			width: 20px;
		}
		a{color: $white;}

		&:last-child{
			&:before{display: none;}
		}
		&.active,&:hover{
			a{color: #FF6F61;}
		}
	}

	&.black{
		a{color: #7C7968;}
	}
}

.space-top-bottom{padding: 60px 0px 90px;}
.space-top-bottom-same{padding: 60px 0px;}

.side-line{
	padding-left: 30px;position: relative;margin-bottom: 45px;color: #4D4C44;
	&:before{content:"";position: absolute;top: 0px;left: 0px;bottom: 0px;width: 3px;background: #FF6F61;}
	ul,ol{
        li{font-size: 16px;line-height: 30px;color: #7C7968;}
    }
	ul {
        margin: 0 0 20px 0;
        li {
            margin: 0px;padding: 0px 0px 0px 20px;position: relative;
            &:before{
                content:"";position: absolute;top: 11px;left: 3px;width: 5px;height: 5px;background: #7CA557;
                @include borderRadius(100%);
            }
        }  
    }
    ol { 
        margin: 0 0 20px 0;counter-reset: my-awesome-counter;
        li {
            margin: 0px;padding: 0px 0px 0px 20px;counter-increment: my-awesome-counter;position: relative;
            &:before{
                content: counter(my-awesome-counter) ".";
                position: absolute;top: 0px;left: 0px;
            }
        }  
    }
}

.side-icon{
	display: flex;font-family: $opensans;align-items:center;margin-bottom: 10px;
	img{max-width: 60px;}
	span{font-size: 20px;font-weight: 700;font-style: italic;text-transform: uppercase;color: #698D48;margin-left: 10px;}
}

.ovhide{overflow: hidden;}

.potrait-list{
	display: flex;flex-wrap:wrap;margin:0px -10px 80px;
	.list{
		position: relative;color: #4D4C44;margin: 0px 10px 40px;
		flex: 1 0 calc(50% - 20px);max-width: calc(50% - 20px);
		background: $white;
		@include boxSizing(border-box);
		span{font-size: 15px;color: #B7B4A2;display: block;margin-bottom: 10px;font-family: $opensans;line-height: 1.2;}
		h5{font-size: 18px;font-weight: 600;}
		figure{
			position: relative;
			&:before{
				content:"";position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;background: rgba(0,0,0,.3);
				@include borderRadius(10px);opacity: 0;@include transition(.3s all ease-out);
			}
			img{width: 100%;@include borderRadius(10px);@include boxShadow(0px 0px 5px 0px rgba(0,0,0,.2));}
		}
		figcaption{
			position: absolute;bottom: 0px;left: 0px;right: 0px;@include borderRadius(0px 0px 10px 10px);min-height: 0px;
			background: $white;padding: 30px 50px;@include boxSizing(border-box);@include transition(.3s all ease-out);
		}

		&:hover{
			figure{
				&:before{opacity: 1;}
			}
			figcaption{
				background: rgba(255,255,255,.9);padding: 50px;
			}
		}
	}

	&.fximg{
		.list{
			font-family: $opensans;@include boxShadow(0px 0px 5px 0px rgba(0,0,0,.2));
			@include borderRadius(10px);
			span{font-size: 12px;}
			h5{font-size: 16px;font-family: $opensans;}
			figure{
				&:before,img{@include borderRadius(10px 10px 0px 0px);}
			}
			figcaption{
				@include position(relative, auto auto auto auto);padding: 20px 30px;
			}
		}
	}
	&.three-col{
		.list{
			flex: 1 0 calc(33.3333333% - 20px);max-width: calc(33.3333333% - 20px);
			margin-bottom: 20px;
		}
	}

	&.nomarg{margin-bottom: 0px;}
}

.potrait-lg{
	position: relative;
	span{font-size: 15px;color: #B7B4A2;display: block;margin-bottom: 10px;font-family: $opensans;line-height: 1.2;}
	h5{font-size: 18px;font-weight: 600;margin-bottom: 14px;}
	p{font-size: 16px;line-height: 28px;color: #7C7968;}
	figure{
		img{@include borderRadius(10px 10px 0px 0px);}
	}
	figcaption{
		position: relative;@include borderRadius(0px 0px 10px 10px);min-height: 0px;
		background: $white;padding: 30px 50px;@include boxSizing(border-box);@include transition(.3s all ease-out);
	}

	ul,ol{
        li{font-size: 16px;line-height: 30px;color: #7C7968;}
    }
	ul {
        margin: 0 0 20px 0;
        li {
            margin: 0px;padding: 0px 0px 0px 20px;position: relative;
            &:before{
                content:"";position: absolute;top: 11px;left: 3px;width: 5px;height: 5px;background: #7CA557;
                @include borderRadius(100%);
            }
        }  
    }
    ol { 
        margin: 0 0 20px 0;counter-reset: my-awesome-counter;
        li {
            margin: 0px;padding: 0px 0px 0px 20px;counter-increment: my-awesome-counter;position: relative;
            &:before{
                content: counter(my-awesome-counter) ".";
                position: absolute;top: 0px;left: 0px;
            }
        }  
    }

	&.fximg{
		figure{
			img{@include borderRadius(10px 10px 0px 0px);}
		}
		figcaption{
			/*padding-right: 170px; */
			@include position(relative, auto auto auto auto);
		}
	}
	&.member-lg {
		.list {
			display: none;
			&.active {display: block;} 
		}
	}
}

.pagination{
	text-align: center;
	a{
		display: inline-block;width: 40px;height: 40px;line-height: 40px;@include borderRadius(100%);
		color: #4D4C44;font-weight: 600;
		&.prev,&.next{width: auto;@include borderRadius(0);color: #B7B4A2;}
		&.prev{margin-right: 23px;}
		&.next{margin-left: 23px;}
		&:hover{color: #7CA557;}
		&.active{background: #7CA557;color: $white;}
	}
}

.popup{
	position: fixed;top: 0px;left: 0px;right: 0px;bottom: 0px;z-index: 1000;
	/*background: rgba(0,0,0,.7);*/
	display: none;overflow: auto;
	.in-popup{
		position: relative;width: 866px;margin: 5% auto;padding-top: 60px;
		/* -webkit-perspective: 3000px;
		-moz-perspective: 3000px;
		perspective: 3000px; */
		z-index: 10;
		.close-popup{
			position: absolute;top: 0px;right: 0px;width: 40px;height: 40px;cursor: pointer;
			span{
				display: block;background: #9F9F9F;height: 5px;@include borderRadius(5px);
				@include transform(rotate(45deg));position: absolute;top: 50%;left: 0px;right: 0px;
				margin-top: -3px;@include transition(.2s all ease-out);
				&:last-child{@include transform(rotate(-45deg));}
			}

			&:hover{
				span{
					@include transform(rotate(-45deg));background: #9F0010;
					&:last-child{@include transform(rotate(45deg));}
				}
			}
		}
		.overflow-box{
			overflow: hidden;@include borderRadius(10px);opacity: 0;
			@include transform(rotateX(-45deg));
			-webkit-transform-origin: 0 0;
    		-moz-transform-origin: 0 0;
		    transform-origin: 0 0;
		    -webkit-transform-style: preserve-3d;
		    -moz-transform-style: preserve-3d;
		    transform-style: preserve-3d;
		    transform-origin: 50% 0;
		    @include transition(.3s all ease-out);
		}

		/*&.mid{width: 590px;}*/
		&.mid{
			width: 455px;
			.pop-gallery-list {
				.numslide {
					margin-top: -50px;
				}
			}
			.slidergallery {
				.slick-arrow {
					&.slick-prev {margin-top: -10px;}
					&.slick-next {margin-top: -10px;} 
				}
			}
		} 
	}
	&.show{
		.in-popup{
			.overflow-box{
				opacity: 1;
				@include transform(rotateX(0deg));
			}
		}
	}
	.inner-table {
        width: 100%;
        height: 100%;
        display: table;
        position: absolute;
        top:0; 
        .inner-cell {
            /*display: table-cell;*/
            vertical-align: middle;
        }
    }
	.overlay-popup {
		position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;z-index: 1;background: rgba(0,0,0,.7);
		width: 100%; height: 100%; 
	}
}

select.select-lg{
	width: 100%;border:none;border-bottom: 1px solid #B7B4A2;padding: 0px 20px 15px 0px;font-family: $sunset;
	font-weight: 700;font-size: 20px;color: #292929;background: url('../images/material/ic_arr_bottom_red.svg')no-repeat right 10px;
}

.box-form{
	.col{
		display: flex;justify-content: space-between;background: #FAFAFA;@include borderRadius(10px);margin-bottom: 10px;align-items: center;
		padding: 0px 20px;
		input[type=text],input[type=email],input[type=number],input[type=password],
		select,textarea{
			width: 86%;border: none;padding: 0px 20px;height: 60px;font-size: 16px;color: #292929;
			background: transparent;@include boxSizing(border-box);
			@include andplaceholder{color: rgba(41,41,41,.3);}
		}
		textarea{padding: 20px 0px 0px;resize: none;}
		label{font-size: 13px;color: #7C7968;word-wrap: break-word;width: 14%;}

		&.no-bg{background: transparent;padding: 0px;}
		&.txarea{
			display: block;padding-top: 20px;padding-bottom: 20px;
			label{width: 100%;}
			textarea{width: 100%;}
		}
		&.error {
            margin-bottom: 35px; position: relative; 
            border:1px solid red;
            input.error {border-color: red;}
            p.error {
                font-size:12px; margin: 0; line-height: 1;
                position: absolute; left: 0; bottom: -20px; 
                color: red;                
            }
            .input_file {
            	position: static !important;
            	p.error {
            	}
            	&:after {
            		right: 0; 
            	}
            }
        }
	}
}


.rightside-slider{
	position: relative;background: url(../images/material/paper_fibers.png) repeat top left;
	h3{font-size: 31px;line-height: 40px;}
	p{font-size: 16px;line-height: 28px;color: #7C7968;}
	.wrap-mid{width: 990px;margin-bottom: 135px;position: relative;z-index: 1;}
	.in-rightside-slider{
		display: flex;justify-content: space-between;position: relative;
		align-items: center;
		.desc{width:470px; padding-top: 50px;}
		.slider-right{
			width: 606px;margin-right: -185px;position: relative;
			.numslide{
				position: absolute;bottom: 130px;right: 39px;color: $white;font-size: 20px;
				font-family: $eczar;
				.num{font-size: 31px;font-weight: 700;}
			}
			.text-rotate{
				position: absolute;top: 65px;right: 70px;color: $white;opacity: .5;font-size: 12px;
				text-transform: uppercase;letter-spacing: 3px;font-family: $opensans;z-index:3;
				@include transform(rotate(-90deg));transform-origin: 100% 100%;text-align: right;
				width: 500px;
			}
		}
	}
	.box-numslide{
		position: relative;background: $white;text-align: center;padding: 11px 22px;width: 200px;
		@include boxSizing(border-box);display: flex;justify-content: space-between;align-items: center;
		margin-top: -25px;z-index: 2;
		.prev-slide,.next-slide{
			width: 30px;height: 16px;display: block;cursor: pointer;
			background: url('../images/material/arr-long-black.svg')no-repeat center;
		}
		.next-slide{@include transform(rotate(180deg));}
		.num-only{font-size: 16px;font-family: $eczar;
			.num{font-size: 25px;font-weight: 700;}
		}
	}

	&.no-bg{background: transparent;}
	&.paddbot{padding-bottom: 130px;}
}
.bot-bg-green{
	padding-bottom: 196px;z-index: 1;overflow: hidden;
	.obj-bg-green{
		position: absolute;bottom: 0px;left: 0px;right: 0px;background: #779D54;height: 550px;z-index: -1;
		.left-obj{
			position: absolute;bottom: -131px;left: -110px;width: 439px;height: 435px;
			background: url('../images/material/left-csr.png')no-repeat center;
		}
		.right-obj{
			position: absolute;top: 0px;right: -111px;width: 343px;height: 390px;
			background: url('../images/material/right-csr.png')no-repeat center;
		}
	}
}
.sliderrightside{
	position: relative;padding: 74px 136px 0px 0px;
	&:before{
		content: "";position: absolute;top: 0px;left: 71px;bottom: 39px;right: -2000px;background: #698D48;
		z-index: -1;
	}
	.list{
		min-height: 550px;display: flex;align-items: center;
		img{@include borderRadius(10px);@include boxShadow(0px 3px 6px 0px rgba(0,0,0,0.26));}
	}
	.slick-arrow{
		padding: 0px;border:none;font-size: 0px;color: transparent;width: 30px;height: 16px;
		background: url("../images/material/arr-right-md.svg") no-repeat center;cursor: pointer;
		position: absolute;bottom: 80px;z-index: 2;
		&.slick-prev{@include transform(rotate(180deg));right: 81px;}
		&.slick-next{right: 29px;}
	}
}

.left-leaf{
	position: absolute;top: 432px;left: -157px;width: 398px;height: 410px;background: url('../images/material/left-leaf.png')no-repeat center;
	&.halfsc{top: 50%;margin-top: -170px;}
}
.left-leaf-two{position: absolute;top: 413px;left: -60px;width: 438px;height: 391px;background: url('../images/material/left-leaf-two.png')no-repeat center;}

.filter-side{
	display: flex;justify-content: space-between;margin-bottom: 21px;
	label{font-size: 14px;color: #7C7968;margin-right: 17px;}
	select{
		@include borderRadius(10px);border:1px solid #EEEEEE;height: 40px;padding: 0px 50px 0px 20px;
		font-size: 14px;background: url('../images/material/arr-bot-grey.svg')no-repeat center right;
		min-width: 170px;
	}
	.left-filter{
		a{
			display: inline-block;vertical-align: middle;font-size: 16px;color: #7C7968;
			&:hover,&.active{color:#B2161B;}
			&.active{font-weight: 700;}
		}
	}
}

.box-label{
	margin-bottom: 15px;
	.label{
		display: inline-block;line-height: 20px;padding: 0px 5px;text-transform: uppercase;font-size: 12px;
		font-family: $opensans;font-weight: 600;color: $white;background: #FF6F61;@include borderRadius(5px);
		margin-bottom: 5px;
		&.green{background: #7CA557;}
		&.yellow{background: #AC9730;}
	}
}
.share-sosmed{
	a{
		display: inline-block;margin-left: 5px; cursor: pointer; 
		&:first-child{margin-left:0px;}
		img {margin-bottom: 0;}
	}
}

.box-img-apps{
	a{
		display: inline-block;margin-right: 20px;
		&:last-child{margin-right: 0px;}
	}
}

.full-window{
	height: calc(100vh - 160px);display: flex;align-items: center;color: #779D54;
	background:#779D54  url('../images/content/banner1.jpg')no-repeat center bottom;
	background-size: cover;z-index: 1;position: relative;
	h2{font-size: 60px;margin-bottom: 20px;}
	p{font-size: 16px;line-height: 30px;margin-bottom: 30px;}
	.in-window{width: 673px;margin: 0px auto;text-align: center;z-index: 3;}
}

.search-list{
	.list{
		padding-bottom: 48px;margin-bottom: 48px;border-bottom: 1px solid #E2DFCE;
		h5{font-family: $sfui;font-size: 16px;font-weight: 700;color: #7C7968;margin-bottom: 8px;}
		p{font-size: 16px;line-height: 26px;color: #7C7968;}
	}
}

.search-obj{
	position: relative; overflow:hidden;  
	.left-circ{
		@include position(absolute, 30% null null -230px);width: 439px;height: 435px;
		background: url('../images/material/left-csr.png')no-repeat center;opacity: .17;
		background-size: 100%;
	}
	.right-obj{
		@include position(absolute, 3% -150px null null);width: 343px;height: 391px;
		background: url('../images/material/right-csr.png')no-repeat center;opacity: .17;
		background-size: 100%;
	}
}

.undermaintenance {
	header {
		.head-bottom {display: none;} 
	}
	.full-window {

	}
	.sc-top {margin-top: 100px;} 
	footer {
		.left-foot {
			.copyright {
				display: block; width: 100%;  
				text-align: center; 
			}
			nav {display: none;}
		}
		.right-foot {display: none;} 
	}
}
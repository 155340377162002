@media all and (max-width: 1300px) {
	.trust-top{
		.left-trust{
			.in-left-trust{width: 490px;}
		}
	}
	.trust-point{
		.list{flex: 1 0 calc(33.3333333333% - 20px);max-width: calc(33.3333333333% - 20px);}
	}
}

@media all and (max-width: 1020px) {
	.trust-top{
		.left-trust,.right-trust{@include boxSizing(border-box);}
		.left-trust{
			padding-left: 60px;width: 50%;
			.in-left-trust{width: 100%;}
		}
		.right-trust{width: 46%;}
	}
	.trust-point{
		.list{
			img{margin-bottom: 13px;}
			h5{font-size: 16px;margin-bottom: 10px;}
			p{font-size: 15px;line-height: 24px;}
		}
	}
}
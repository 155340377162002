@media all and (max-width: 1300px) {
	/* general
	==========================*/
	h3{font-size: 25px;line-height: 1.2;}
	h4{font-size: 20px;line-height: 1.2;}

	.btn-shopnow{line-height: 50px;}
	.sc-top{margin-top: 130px;}
	.wrapper{width: 770px;@include boxSizing(border-box);}
	.wrap-mid{
		width: 770px; 
		&.result {min-height: 380px;}
	}
	.btn-rounded{
		height: 50px;line-height: 50px;font-size: 16px;
		&.btn-mid{height: 40px;line-height: 40px;font-size: 14px;}
	}
	.link-inline{font-size: 13px;}
	/* ==========================*/


	/* header
	==========================*/

	header{
		.wrapper{width: 100%;padding-left: 60px;padding-right: 0px;}
		.head-top{
			padding: 21px 0px 22px;
			&:before{
				left:-120px;background-size: 100%;width: 250px;
			}
			&:after{
				top: -60px;right: -150px;background-size: 100%;width: 250px;
			}
			.logo{
				img{width: 193px;}
			}
		}
		.head-bottom{
			.burger-button{
				display: inline-block;margin-top: 14px;
				.box-burger{
					position: relative;width: 30px;height: 22px;display: inline-block;
					vertical-align: middle;margin-right: 8px;
					span{
						width: 30px;height: 4px;background: #698D48;
						@include borderRadius(4px);display: inline-block;
						@include position(absolute, 0px 0px null);
						&:nth-child(2){top:9px;}
						&:last-child{top:18px;}
					}
				}
				label{display: inline-block;vertical-align: middle;}

				&.close-burger{
					@include position(fixed, 17px null null 285px);
					z-index: 10;
					.box-burger{
						span{
							top: 9px;
							&:first-child{@include transform(rotate(45deg));}
							&:nth-child(2){display: none;}
							&:last-child{@include transform(rotate(-45deg));}
						}
					}
					label{display: none;}
				}
			}
			.lang-head{line-height: 50px;}
			.search-head{
				.btn-search{height: 50px;}
			}
			.wrapper{
				ul{float: none;}
				> ul{
					width: 350px;max-width: 90%;display: none;
					padding: 70px 40px 50px;@include boxSizing(border-box);
					@include position(fixed, 0px null 0px 0px);
					overflow-y: auto;max-height: 100vh;z-index: 5;
					.overlay-bg {
						content:"";background: rgba(0,0,0,0.5);z-index: -1;
						@include position(fixed, 0px);
					}
					/* &:before{
					 	content:"";background: rgba(0,0,0,0.5);z-index: -1;
					 	@include position(fixed, 0px);
					 } */
					&:before {
						background: #F9FAFA; content: ""; width: 350px; height: 70px;  
						max-width: 90%;
						@include position(fixed, 0px null null 0px); 
						z-index: 2;
						@include transition(.3s all ease-out);
						transition-delay: .2s;
						-webkit-transition-delay: .2s;
						opacity: 0;
					} 
					&:after{
						content:"";background: #F9FAFA;z-index: -1;width: 0%;
						opacity: 0;max-width: 90%;
						@include position(fixed, 0px null 0px 0px);
						@include transition(.3s all ease-out);
						transition-delay: .2s;
						-webkit-transition-delay: .2s;
					}
					> li {
						display: block;margin-right: 0px;opacity: 0;
						a{display: block;}
						> a{
							line-height: 20px;border-bottom: 1px solid rgba(227, 227, 227, 0.8);
							padding:15px 0px;
						}
						> ul{
							position: relative;opacity: 1;visibility: visible;
							top: auto;left: auto;margin-left: 0px;width: auto;
							padding-top: 10px;display: none;
							@include transition(0s);
							&:before,&:after{display:none;}
							> li{
								border-bottom: none;@include borderRadius(0px);
								>a{padding: 5px 0px 5px 20px;}
								&:first-child,&:last-child{@include borderRadius(0px);}
							}
						}

						&.parent{
							> a{background: url('../images/material/arr-bot-grey.svg')no-repeat right;}
						}
						&.bottom-mobile {
							padding-top: 30px; 
							display: none !important;
							.lang-mobile {
								margin-bottom: 15px; 
								a {
									display: inline-block; 
									&.active {
										color: #1F5B29; 
									}
								}
							}
							.search-mobile {
								position: relative; 
								form {
									width: 100%; 
									input[type="text"] {
										width: 100%; padding: 0 50px 0 20px; 
										height: 40px; line-height: 40px; border:1px solid #ddd; 
										box-sizing: border-box; 
										font-size: 14px; 
									}
									button {
										position: absolute; width: 40px; height: 40px; top: 0; right: 0; 
										padding: 0; border:0; 
										background: url("../images/material/ic-search.svg") no-repeat center;
										cursor: pointer;   
									}
								}
							}
						}
					}

					&.show{
						&:before{
							opacity:1; 
							transition-delay: 0s;
							-webkit-transition-delay: 0s;
						}
						&:after{
							width:350px;opacity: 1;
							transition-delay: 0s;
							-webkit-transition-delay: 0s;
						}
					}
				}
			}
		}
	}
	/* ==========================*/

	/* content
	==========================*/
	.side-title{
		flex-wrap: wrap;
		h3{width: 100%;margin-bottom: 10px;}
	}
	.side-thumb{
		.ls-thumb{
			figcaption{
				p{max-height: 90px;overflow: hidden;}
			}
		}

		&.fullwidth{
			.ls-thumb{
				align-items: flex-start;margin-bottom: 45px;
				figure{width: 40%;}
				figcaption{width: 55%}
			}
		}
	}
	.banner{
		figcaption{width: 800px;}
	}
	.popup{
		.in-popup{
			width: 760px;
			&.mid {
				.pop-gallery-list {
					.numslide {
						top: 50%; 
					}
					.slidergallery {
						.slick-arrow {
							top: 50%; 
						}
					}
				}
			}
		}
	}
	.potrait-list{
		.list{
			span{font-size: 14px;margin-bottom: 5px;}
			h5{font-size: 16px;}
			figcaption{padding: 20px 35px;}

			&:hover{
				figcaption{padding: 20px 35px;}
			}
		}

		&.fximg{
			.list{
				h5{font-size: 14px;}
				figcaption{padding: 14px 23px;}
			}
		}
	}
	.rightside-slider{
		h3{font-size: 30px;line-height: 42px;}
		.wrap-mid{width: 770px;} 
		.in-rightside-slider{
			align-items: flex-start;
			.desc{
				width: 43%;padding-top: 74px;
				h3{font-size: 20px;line-height: 30px;}
			}
			.slider-right{
				width: 68%;margin-right: -17%;
				.text-rotate{top: 74px;right: 42px;}
			}
		}
	}
	.left-leaf{left: -240px;top: 625px;}
	.left-leaf-two{
		background-size: 100%;width: 380px;height: 350px;
		left: -100px;top: 470px;
	}
	.banner{
		figcaption{
			h2{font-size: 30px;}
		}
	}
	.bg-bot-img{
		&:before{left:-100px;bottom: -100px;}
		&:after{
			background-size: 100%;width: 300px;height: 300px;
			bottom: -120px;
		}
	}
	.filter-side{
		margin-bottom: 30px;
		.left-filter{
			a{line-height: 40px;}
		}
	}
	.box-label{
		margin-bottom: 10px;
		.label{font-size: 11px;}
	}
	.full-window{
		h2{font-size: 45px;}
		p{line-height: 24px;}
	}
	.bot-bg-green{
		padding-bottom: 83px;
		.obj-bg-green{
			height: 330px;
			.left-obj{background-size: 100%;width: 330px;height: 330px;}
			.right-obj{background-size: 100%;width: 250px;height: 300px;}
		}
	}
	.search-obj{
		.left-circ{width: 250px;height: 260px;left: -150px;}
		.right-obj{width: 200px;height: 250px;right: -100px;}
	}
	.pop-gallery-list {
		.numslide {
			top: 40%; 
		}
		.slidergallery {
			.slick-arrow {
				top: 40%; 
			}
		}
	}
	/* ==========================*/


	/* footer
	==========================*/
	footer{
		display: flex;
		nav{
			a{font-size: 12px;margin-right: 30px;}
		}
		.left-foot{
			flex-wrap: wrap;flex: 1 0 65%;max-width: 65%;
			@include boxSizing(border-box);
			.copyright,nav{width: 100%;}
			.copyright{
				margin-bottom: 10px;
				br{display: none;}
			}
		}
		.right-foot{
			display: flex;align-items: flex-end;
			a{margin-bottom: 30px}
		}
	}
	/* ==========================*/

	.undermaintenance {
		.sc-top {margin-top: 80px;} 
		footer {
			.left-foot {
				flex: 1 0 100%;max-width: 100%; 
			}
		}
	}	

}


@media all and (max-width: 1020px) {
	/* general
	==========================*/
	h4{line-height: 1.4;}
	h5{font-size: 15px;}
	
	.btn-submit{height: 50px;line-height: 50px;font-size: 14px;}
	.btn-rounded{height: 40px;line-height: 40px;font-size: 14px;}
	.wrap-small,.wrapper,.wrap-mid{
		width: 100%;padding-left: 60px;padding-right: 60px;
		@include boxSizing(border-box);
	}
	.wrap-mid {
		&.result {min-height: 630px;} 
	}	
	.side-line{margin-bottom: 30px;}
	.space-top-bottom{padding: 40px 0px;}
	/* ==========================*/

	/* content
	==========================*/
	.std-content{
		p{font-size: 15px;line-height: 24px;}
		ul, ol{
			li{font-size: 15px;line-height: 24px;}
		}
		table{
			th, td{font-size: 13px;}
		}
		&.hgreen{
			h5{font-size: 15px;}
		}
	}
	.side-thumb{
		flex-wrap: wrap;
		.ls-thumb{
			width: 100%;margin-bottom: 30px;
			figcaption{
				p{font-size: 15px;line-height: 24px;}
			}
			&:last-child{margin-bottom:0px;}
		}

		&.fullwidth{
			.ls-thumb{
				margin-bottom: 40px;
				figure{width: 47%;}
				figcaption{
					width: 48%;
					p{margin-bottom: 20px;}
				}
			}
		}
	}
	.bg-bot-img{
		&:before{background-size:100%;width: 220px;height: 200px;bottom: -75px;}
		&:after{width: 220px;height: 220px;bottom: -80px;}
	}
	.banner{
		figure{
			height: 270px;
			img{height: 100%;width: auto;min-width: 100%;max-width: none;}
		}
		figcaption{width: auto;padding-left: 60px;padding-right: 60px;}
	}
	.potrait-list{
		margin-bottom: 30px;
		.list{
			margin-bottom: 30px;
			span{font-size: 13px;}
			h5{font-size: 14px;}
			figcaption{padding:20px 30px;}

			&:hover{
				figcaption{padding:20px 30px;}
			}
		}

		&.three-col{
			.list{flex: 1 0 calc(50% - 20px);max-width: calc(50% - 20px);}
		}
		&.fximg{
			.list{
				figcaption{padding: 14px 23px;}
				&:hover{
					figcaption{padding: 14px 23px;}
				}
			}
		}
	}
	.side-icon{
		img{max-width: 40px;}
		span{font-size: 16px;}
	}
	.popup{
		.in-popup{width: 80%;}
	}
	.sliderrightside{
		padding-right: 92px;
		.list{min-height: 330px;}
		.slick-arrow{
			background-size: 100%;width: 23px;bottom: 65px;
			&.slick-prev{right: 56px;}
			&.slick-next{right: 18px;}
		}
	}
	.rightside-slider{
		h3{font-size: 24px;line-height: 1.3;}
		p{font-size: 15px;}
		.wrap-mid{width: 100%;margin-bottom: 100px;}
		.in-rightside-slider{
			.desc{width: 45%;}
			.slider-right{
				margin-right: -60px;width: 58%;
				.text-rotate{width: 230px;top: 40px;line-height: 1.5;height: 36px;right: 30px;}
				.numslide{
					font-size: 13px;bottom: 92px;right: 27px;
					.num{font-size: 22px;}
				}
			}
		}
		.box-numslide{
			.num-only{
				font-size: 13px;
				.num{font-size: 22px;}
			}
		}

		&.paddbot{padding-bottom: 80px;}
	}
	.left-leaf{background-size: 100%;width: 350px;height: 350px;left: -200px;top: 680px;}
	.filter-side{
		.left-filter{
			a{font-size: 14px;}
		}
	}
	.bot-bg-green{
		.obj-bg-green{
			.left-obj{width: 300px;height: 300px;}
			.right-obj{width: 200px;height: 250px;}
		}
	}
	.search-list{
		.list{
			padding-bottom: 30px;margin-bottom: 30px;
			p{font-size: 14px;line-height: 20px;}
		}
	}
	
	.side-line {
		ul,ol{
	        li{font-size: 15px;line-height: 28px;}
	    }
    }	
	
	.full-window {
		min-height: 300px;
	}


	/* ==========================*/

	/* footer
	==========================*/
	footer{
		flex-wrap: wrap;text-align: center;
		.left-foot,.right-foot{
			flex: 1 0 100%;max-width: 100%;
			@include boxSizing(border-box);
		}
		.right-foot{
			padding: 30px 50px;justify-content: center;align-items: center;
			a{margin-bottom: 0px;}
		}
	}
	/* ==========================*/
}
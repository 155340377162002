@media all and (max-width: 1300px) {
	.box-aside{
		position: relative;
		&:before{
			content:"";@include position(fixed, 0px);background: rgba(0,0,0,0.3);
			z-index: 2;opacity: 0;display: none;
			@include transition(.3s opacity ease-out);
		}
		.submenu-aside{
			display: block;cursor: pointer;z-index: 1;
			@include position(absolute, 0px 0px null null);
			> span{
				text-transform: uppercase;font-size: 14px;font-weight: 600;
				vertical-align: middle;display: inline-block;margin-right:15px; 
			}
			.dots-submenu{
				height: 20px;display: inline-block;vertical-align: middle;
				span{
					width: 6px;height: 6px;display: inline-block;margin-right: 6px;
					@include borderRadius(50%);background: #1F5B29;
					&:last-child{margin-right:0px;}
				}
			}
		}
		aside{
			display: none;
			z-index: 2;width: 300px;background: $white;@include borderRadius(8px);
			@include position(absolute, 50px 0px null null);
			@include boxShadow(0px 0px 50px 0px rgba(0,0,0,0.22));
			&:before{
				content:"";@include position(absolute, -19px 50px null null);
				border-left: 11px solid transparent;border-right: 11px solid transparent;
				border-bottom: 19px solid $white;
			}
			> ul{
				overflow-y: auto;max-height: 410px;
				> li{
					padding: 0px 30px;border: none;
					> a{
						border-bottom: 1px solid #EEEEEE;
					}
					> ul{border-left: none;}

					&:last-child{border:none;}
				}
			}
		}
		article{width: 100%;}

		&.overflow{
			&:before{display: block;opacity: 1;}
		}
	}

	.acc-sidelist{
		.list{
			&.limited{
				.desc{max-height: 290px;}
				&.active{
					.desc{max-height: 800px;}
				}
			}
		}
	}
	.slidercsr{
		.list{
			figcaption{
				h3{font-size: 20px;line-height: 30px;margin-bottom: 15px;}
			}
		}
	}
}

@media all and (max-width: 1020px) {
	.acc-sidelist{
		.list{
			figure{width: 40%;}
			.desc{
				width: 58%;
				p{font-size: 15px;line-height: 24px;}
			}
			&.limited{
				.desc{max-height: 294px;}
			}
		}
	}
	.box-aside{
		.submenu-aside{
			> span{font-size: 12px;}
		}
		aside{
			> ul{
				padding: 10px 0px;
				> li{
					padding: 0px 20px;
					h5{font-size: 14px;}
					> a{padding: 15px 15px 15px 0px;}
					> ul{padding: 10px 0px;margin-bottom: 0px;}

					&:last-child{
						> a{border: none;}
					}
				}
			}
		}
	}
	.acc-droplist{
		.list{
			h5{font-size: 18px;}
			p{font-size: 15px;}
		}
	}
	.download-side{
		.list{
			figure{width: 25%;}
			.desc{width: 70%;}
		}
	}
	.data-download{
		h5{font-size: 15px;}
	}
	.slidercsr{
		.list{
			figcaption{
				padding: 20px;
				h3{font-size: 18px;line-height: 26px;}
			}
		}
	}
}
.box-aside{
	display: flex;justify-content: space-between;
	.submenu-aside{display: none;}
	aside{
		width: 270px;
		> ul{
			> li{
				border-top: 1px solid #EEEEEE;
				h5{font-size: 16px;font-weight: 600;color: #7C7968;@include transition(.1s all ease-out);}
				a{color: #7C7968;display: block;}
				> a{
					color: #7C7968;padding: 22px 20px 22px 0px;@include transition(.1s all ease-out);
					position: relative;
					&:before{
						content:"";@include position(absolute, 0px 0px 0px null);width: 20px;
						background: url('../images/material/arr-bottom-grey.svg')no-repeat center;
						@include transition(.2s all ease-out);
					}
				}
				> ul{
					border-left: 1px solid #EEEEEE;margin-bottom: 23px;
					display: none;
					> li{
						font-size: 14px;line-height: 20px;position: relative;padding: 5px 0px;
						padding-left: 15px;
						&:before{
							content:"";position: absolute;top: 0px;left: -1px;bottom: 0px;width: 2px;
							background: #B2161B;height: 0px;margin: auto 0px;@include transition(.1s all ease-out);
						}

						&:hover,&.active{
							&:before{height:100%;}
							a{color: #B2161B;}
						}
						&.active{
							a{font-weight: 700;}
						}
					}
				}

				&:last-child{border-bottom: 1px solid #EEEEEE;}
				&:hover,&.active{
					> a, > a h5{color: #B2161B;}
				}
				&.active{
					> a, > a h5{color: #B2161B;}
					> a{
						&:before{@include transform(rotate(180deg));}
					}
					> ul{display: block;}
				}
				&.noacc{
					> a{
						&:before{display: none;}
					}
				}
			}
		}
	}
	article{width: 800px;}
}

.acc-sidelist{
	.list{
		display: flex;justify-content: space-between;border-top: 1px solid #EEEEEE;padding: 30px 0px;
		position: relative;
		figure{
			width: 230px;
			img{@include borderRadius(10px);}
		}
		.desc{
			width: 500px;padding-bottom: 20px;@include boxSizing(border-box);
			overflow: hidden;@include transition(.5s max-height ease-in-out);
			h4{color: #7CA557;margin-bottom: 5px;font-family: $sfui;font-weight: 700;}
			p{font-size: 16px;line-height: 28px;color: #7C7968;margin-bottom: 20px;}
			.pos{
				display: block;font-size: 16px;line-height: 20px;color: #7C7968;font-family: $opensans;
				margin-bottom: 26px;
			}
		}
		.acc-btn-more{
			position: absolute;bottom: 30px;right: 0px;width: 30px;height: 30px;background: #FF6F61;
			@include borderRadius(100%);display: block;
			&:before,&:after{
				content:"";display: block;position: absolute;top: 50%;left: 50%;width: 12px;height: 2px;
				background: $white;@include borderRadius(2px);margin: -1px 0px 0px -6px;
			}
			&:after{@include transform(rotate(90deg));}
		}

		&:last-child{border-bottom: 1px solid #EEEEEE;}
		&.limited{
			.desc{max-height: 280px;}
		}
		&.active{
			.desc{max-height: 800px;}
			.acc-btn-more{
				&:after{@include transform(rotate(0deg));}
			}
		}
	}
}

.download-side{
	.list{
		display: flex;justify-content: space-between;padding: 45px 0px;border-top: 1px solid #EEEEEE;
		figure{
			width: 160px;
			img{@include borderRadius(10px);width: 100%;}
		}
		.desc{
			width: 600px;
			h4{color: #7CA557;margin-bottom: 20px;font-family: $sfui;font-weight: 700;}
			p{font-size: 16px;line-height: 28px;color: #7C7968;margin-bottom: 20px;}
		}

		&:last-child{border-bottom: 1px solid #EEEEEE;}
	}
}
.download-potrait{
	h4{color: #7CA557;margin-bottom: 20px;font-family: $sfui;font-weight: 700;}
	p{font-size: 16px;line-height: 28px;color: #7C7968;margin-bottom: 20px;}
	.list{padding: 45px 0px;border-top: 1px solid #EEEEEE;}
}

.data-download{
	display: block;padding: 18px 70px 18px 30px;@include borderRadius(10px);background: #FAFAFA;
	border: 1px solid #FAFAFA;margin-bottom: 10px;position: relative;
	h5{font-size: 16px;line-height: 24px;font-weight: 600;color: #292929;font-family: $sfui;}
	span{display: block;font-size: 13px;line-height: 18px;color: #7C7968;}
	.link-download{
		display: block;width: 30px;height: 30px;@include borderRadius(100%);margin: auto 0px;
		@include position(absolute, 0px 31px 0px null);
		background: #FF6F61 url('../images/material/arr-download.svg')no-repeat center;
	}

	&:hover{
		border-color: #8DB766;
	}
}

.acc-droplist{
	.list{
		border-bottom: 1px solid #EEEEEE;
		h5{font-size: 20px;font-weight: 700;color: #7CA557;font-family: $sfui;}
		p{font-size: 16px;margin-bottom: 0px;}
		.btn-acc{
			padding: 26px 40px 26px 0px;position: relative;cursor: pointer;
			.plus-btn-acc{
				position: absolute;top: 50%;right: 0px;width: 30px;height: 30px;display: block;
				@include borderRadius(100%);background: #FF6F61;margin-top: -15px;
				&:before,&:after{
					content:"";position: absolute;top: 50%;left: 0;right: 0px;margin: 0px auto;
					width: 12px;height: 2px;background: $white;@include borderRadius(2px);
					margin-top: -1px;
				}
				&:after{@include transform(rotate(90deg));@include transition(.2s all ease-out);}
			}
		}
		.drop-acc{display: none;padding-bottom: 30px;}

		&:first-child{border-top: 1px solid #EEEEEE;}
		&:hover{border-bottom-color: #7CA557;}
		&.active{
			.btn-acc{
				.plus-btn-acc{
					&:after{@include transform(rotate(0deg));}
				}
			}
			.drop-acc{display: block;}
		}
	}
}

.ttl-csr{
	text-align: center;margin-bottom: 48px;
	span{
		display: block;text-transform: uppercase;letter-spacing: 3px;font-size: 12px;font-family: $opensans;
		color: #7C7968;margin-bottom: 14px;
	}
	h3{font-size: 39px;line-height: 54px;margin-bottom: 14px;}
	p{font-size: 16px;line-height: 26px;color: #7C7968;}
}

.slidercsr {
	.list{
		position: relative;
		figure{
			img{width: 100%;@include borderRadius(10px);@include boxShadow(0px 3px 6px 0px rgba(0,0,0,0.16));}
		}
		figcaption {
			position: absolute;top: 0px;right: 0px;bottom: 0px;background: rgba(23,23,23,.8);
			@include boxShadow(0px 3px 6px 0px rgba(0,0,0,0.16));width: 43%;@include boxSizing(border-box);
			display: flex;align-items: center;flex-wrap:wrap;padding: 50px;color: $white;
			@include borderRadius(0px 10px 10px 0px);
			h3{font-size: 31px;line-height: 41px;margin-bottom: 26px;}
			ul,ol{
				li{
					position: relative;padding-left: 30px;font-size: 14px;line-height: 24px;margin-bottom: 20px;
					&:before{
						content:"";position: absolute;top: 4px;left: 0px;width: 16px;height: 16px;
						background: url('../images/material/dots-green.svg')no-repeat center;
					}
				}
			}
			.in-caps{max-height: 100%;overflow-y: auto;padding-right: 15px;}
		}
	}
}
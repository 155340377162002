@media all and (max-width: 740px) {
	.product-list{
		.list{
			flex-wrap: wrap;margin-bottom: 50px;
			figure,figcaption{width: 100%;}
			figure{margin-bottom: 30px;}
			figcaption{
				h3{margin-bottom: 10px;}
			}

			&:nth-child(even){
				figure{order: 1;}
				figcaption{order: 2;}
			}
		}
	}
}
@media all and (max-width: 740px) {
	/* general
	==========================*/
	h3{font-size: 20px;}
	h4{font-size: 18px;}

	.btn-shopnow{
		font-size: 13px;padding: 0px 21px 0px 56px;
		&:before{left:17px;background-size: 80%;}
	}
	.wrap-small, .wrapper, .wrap-mid{
		padding-left: 30px;padding-right: 30px;
	}
	.wrap-mid {	
		&.result {min-height: 430px;}  
	}
	/* ==========================*/

	/* header
	==========================*/
	header{
		.wrapper{padding-left: 20px;}
		.head-top{
			padding: 15px 0px;
			&:before,&:after{display: none;}
			.logo{
				img{width: 160px;}
			}
		}
		.head-bottom{
			.lang-head,.search-head{display: none;}
			.burger-button{
				&.close-burger{left: 257px;}
			}
			.wrapper > {
				ul > {
					li {
						&.bottom-mobile {
							display: block !important; 
						}
					}
				}
			}
		}
	}
	/* ==========================*/

	/* content
	==========================*/
	.sc-top{margin-top: 111px;}
	.side-thumb{
		.ls-thumb{
			flex-wrap: wrap;margin-bottom: 35px;
			figure,figcaption{width: 100%;}
			figure{margin-bottom: 18px;}
		}

		&.fullwidth{
			.ls-thumb{
				figure,figcaption{width: 100%;}
			}
		}
	}
	.bg-bot-img{
		display: none; 
		&:before,&:after{display:none;}
	}
	.banner{
		figure{height: 175px;}
		figcaption{
			padding-left: 30px;padding-right: 30px;
			h2{font-size: 20px;}
		}
	}
	.breadcrumbs{
		li{
			margin-right: 33px;font-size: 10px;
			&:before{width:15px;}
		}
	}
	.potrait-list{
		.list{
			flex-basis: 100%;max-width: 100%;margin: 0px 0px 20px;
			@include boxShadow(0px 0px 5px 0px rgba(0, 0, 0, 0.2));
			@include borderRadius(10px);
			figure{
				img{
					@include borderRadius(10px 10px 0px 0px);
					@include boxShadow(none);
				}
			}
			figcaption{@include position(relative, auto);}
		}

		&.three-col{
			margin-left: 0px;margin-right: 0px;
			.list{flex-basis: 100%;max-width: 100%;}
		}
	}
	.potrait-lg{
		span{font-size: 13px;}
		p{font-size: 14px;line-height: 24px;}
		figcaption{padding: 25px;}
		ul,ol{
	        li{
	        	font-size: 14px;line-height: 24px;
	        }
	    }
	    ul {
	    	li {
	    		padding-bottom: 5px;
	    	}
	    }
	    ol {
	    	li {
	    		padding-bottom: 5px;
	    	}
	    }
	}
	.pagination{
		a{
			width: 30px;height: 30px;line-height: 30px;
			font-size: 13px;
			&.prev{margin-right: 15px;}
			&.next{margin-left: 15px;}
		}
	}
	.popup{
		.in-popup{
			width: 85%;padding-top: 40px;
			.close-popup{width: 30px;height: 30px;}
			&.mid {
				width: 455px; 
				.pop-gallery-list {
					.numslide {
						top: 50%;
						margin-top: 0;
					}
				}
				.slidergallery {
					.slick-arrow {
						top: 50%; 
						margin-top: 30px;
						&.slick-prev {margin-top: 30px;}
						&.slick-next {margin-top: 30px;}
					}
				}
			}
		}
	}
	.box-form{
		.col{
			input[type=text],input[type=email],input[type=number],input[type=password],
			select,textarea{padding-right: 0px;}
			label{width: 30%;}
		}
	}
	.side-line{padding-left: 20px; padding-right: 40px;} 
	.std-content{
		table{
			display: block;
			thead{display: none;}
			tbody{
				display: block;
				tr{
					display: block;padding: 20px;background: #FAFAFA;
					border: none;margin-bottom: 10px;
					@include borderRadius(10px);
					th, td{
						display: block;padding: 0px;margin-bottom: 20px;
						text-align: left;background: transparent;
						.title-td{
							display: block;font-weight: 600;color: #698D48;
							span{display: block;font-weight: 400;}
						}
						&:last-child{text-align: left;margin-bottom: 0px;}
					}
				}
			}
			tfoot{
				display: block;
				tr{
					display: block;padding: 20px;background: #B7B4A2;
					@include borderRadius(10px);
					th, td{
						display: block;padding: 0px;background:transparent;
						text-align: left;
					}
				}
			}
		}
	}

	.rightside-slider{
		.wrap-mid{margin-bottom: 60px;}
		.in-rightside-slider{
			flex-wrap: wrap;
			.desc,.slider-right{width: 100%;}
			.desc{
				order: 2;padding-top: 40px;
				h3{font-size: 18px;}
			}
			.slider-right{
				order: 1;margin-right: 0px;
				.numslide{right: -12px;}
				.text-rotate{right: -10px;}
			}
		}
	}
	.sliderrightside{
		padding-right: 50px;
		.slick-arrow{
			&.slick-prev{right: 15px;}
			&.slick-next{right: -18px;}
		}
	}
	.bot-bg-green{
		.obj-bg-green{height: 442px;}
	}
	.space-top-bottom-same{padding: 40px 0px;}
	.filter-side{
		flex-wrap: wrap;
		.left-filter,.right-filter{width: 100%;}
		.left-filter{margin-bottom: 10px;}
	}
	.box-img-apps{
		a{
			margin-right: 5px;
			img{max-width: 120px;}
		}
	}
	.full-window{
		height: auto;height: calc(100vh - 111px);min-height: 300px;
		h2{font-size: 30px;margin-bottom: 10px;}
		p{font-size: 14px;}
		.in-window{
			width: 100%;@include boxSizing(border-box);padding: 40px 30px;
		}
	}
	.left-leaf,.left-leaf-two{display: none;}
	.search-obj{
		.left-circ{width: 130px;height: 140px;left: -90px;}
		.right-obj{width: 130px;height: 170px;right: -70px;}
	}
	/* ==========================*/

	footer{
		nav{
			a{margin-right: 10px;}
		}
		.left-foot{padding: 30px 20px;}
		.right-foot{
			a{font-size: 12px;margin-right: 20px;}
		}
	}
	
	.undermaintenance {
		.sc-top {margin-top: 61px;} 
	}

}

@media all and (max-width: 479px) {
	.popup{
		.in-popup{			
			&.mid {
				width: 300px; 
				.pop-gallery-list {
					.numslide {
						top: 40%;
					}
				}
				.slidergallery {
					.slick-arrow {
						top: 40%; 
					}
				}
				.slidergallery {
					.slick-arrow {
						&.slick-prev {margin-top: -55px;}
						&.slick-next {margin-top: -55px;}
					}
				}
			}
		}
	}
}

@media all and (max-width: 350px) {
	header{				
		.head-bottom{			
			.burger-button{
				&.close-burger{left: 217px;}
			}			
		}
	}
	.wrap-small, .wrapper, .wrap-mid{
		padding-left: 20px;padding-right: 20px;
	}
	.box-form {
		.col {
			padding-left: 15px;
		}
	}
}
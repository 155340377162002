@media all and (max-width: 1600px) {
	
	.wrap-mid {
		&.result {min-height: 420px;}
	}

	header{
		.head-bottom{
			ul{
				> li{
					> ul{width: 190px;margin-left: -95px;}
				}
			}
		}
	}
	.thumb-img{
		.list{
			width: 260px;
			figcaption{padding: 20px;}
		}
	}

	footer{
		.left-foot{padding: 30px 50px;}
		.right-foot{
			padding-left: 30px;padding-right: 30px;
			a{margin-right: 40px;}
		}
	}
	

}

@media all and (max-width: 1390px) {
	.wrap-mid {
		&.result {min-height: 280px;}
	}
}
@media all and (max-width: 740px) {
	.trust-top{
		flex-wrap: wrap;
		.left-trust{width: 100%;padding: 0px 30px 30px;}
		.right-trust{width: 100%;padding-left: 30px;}
	}
	.trust-point{
		margin: 0px;
		.list{flex-basis: 100%;max-width: 100%;}
	}
}
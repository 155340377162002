@media all and (max-width: 740px) {
	.box-aside{
		.submenu-aside{
			top: 5px;
			> span{display: none;}
		}
		aside{
			width: 100%;
			&:before{top:-15px;right: 4px;}
			> ul{max-height: 290px;}
		}
	}
	.acc-sidelist{
		.list{
			flex-wrap: wrap;
			figure,.desc{width: 100%;}
			figure{
				margin-bottom: 20px;
				img{width: 100%;}
			}
			.desc{max-height: none;padding-bottom: 0px;}
			.acc-btn-more{display: none !important;}

			&.limited{
				.desc{max-height: none;}
				&.active{
					.desc{max-height: none;}
				}
			}
		}
	}
	.acc-droplist{
		.list{
			h5{font-size: 16px;}
			.btn-acc{
				padding-right: 0px;padding-bottom: 50px;
				.plus-btn-acc{top: auto;margin-top: 0px;bottom: 15px;}
			}
		}
	}
	.download-side{
		.list{
			flex-wrap: wrap;padding: 30px 0px;
			figure,.desc{width: 100%;}
			figure{
				text-align: center;margin-bottom: 20px;
				img{width: auto;max-width: 80%;}
			}
			.desc{
				h4{text-align: center;margin-bottom: 15px;}
			}
		}
	}
	.data-download{
		padding: 14px 55px 14px 30px;
		h5{font-size: 14px;line-height: 20px;}
		.link-download{right: 20px;}
	}
	.slidercsr{
		.list{
			figure{
				img{
					@include borderRadius(10px 10px 0px 0px);
					height: 340px;object-fit: cover;
				}
			}
			figcaption{
				@include position(relative, auto);width: 100%;
				@include borderRadius(0px 0px 10px 10px);
				.in-caps{max-height: 300px;}
			}
		}
	}
}
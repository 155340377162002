@media all and (max-width: 1600px) {
	.homecontent{
		.in-homecontent{
			width: 1270px;
			> section{
				&.facilities-home,&.news-home,&.magazine-home{
					.wrapper{width: 90%;}
				}
				&.magazine-home{
					.left-magazine{width: 47%;}
					.right-magazine{
						.slidermagazine{width: 602px;margin-right: -60px;}
					}
				}
			}
		}
		.wrapper{width: 100%;}
	}
	.slidermagazine{
		.list{width: 240px;}
		.slick-arrow {
			&.slick-next {
				right: -25px; 
			}
		}
	}
}
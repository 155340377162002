@media all and (max-width: 1300px) {
	.box-career{
		.title-career{
			h3{font-size: 30px;line-height: 42px;}
			p{font-size: 15px;}
		}
	}
	.no-banner{margin-top: 130px;}
	.career-detail{
		h3{font-size: 30px;}
	}
}

@media all and (max-width: 1020px) {
	.box-career{
		.title-career{
			h3{font-size: 24px;line-height: 36px;}
		}
	}
	.career-detail{
		h3{font-size: 25px;}
	}
}
@media all and (max-width: 1300px) {
	.health-detail{
		h2{font-size: 60px;line-height: 60px;}
		.txbg-large{font-size: 100px;}
	}
	.slidermagazinedetail{
		.list{width: 221px;}
	}
	.event-list{
		.list{
			flex: 1 0 calc(33.33333333% - 30px);
			max-width: calc(33.33333333% - 30px);
			figcaption{
				padding: 17px 19px;
				h5{font-size: 15px;}
			}
		}
	}
	.slider-promo{
		.slick-arrow{width: 55px;height: 55px;}
	}
	.partner-link{
		a{
			margin-bottom: 15px;
			img{max-width: 150px;}
		}
	}
}

@media all and (max-width: 1020px) {
	.health-detail{
		h2{font-size: 46px;line-height: 50px;}
		blockquote{font-size: 16px;line-height: 32px;}
	}
	.box-slidermagazine{
		h3{font-size: 25px;margin-bottom: 35px;}
		.wrapper{padding-left: 100px;}
	}
	.slidermagazinedetail{
		margin-right: -60px;
		.list{
			margin-right: 25px;
			figcaption{padding: 16px;font-size: 13px;line-height: 18px;}
		}
		.slick-arrow{left: -75px;}
	}
	.event-list{
		margin-left: -10px;margin-right: -10px;
		.list{
			margin-left: 10px;margin-right: 10px;
			flex: 1 0 calc(33.33333333% - 20px);
			max-width: calc(33.33333333% - 20px);
		}
	}
	.past-issues{
		h3{font-size: 25px;}
	}
	.slider-promo{
		.slick-arrow{
			width: 45px;height: 45px;background-size: 45%;
			&.slick-prev{left: 17px;}
			&.slick-next{right: 17px;}
		}
	}
	.promobox{
		h3{font-size:25px;}
	}
	.pop-gallery-list{
		.numslide{margin-top: -100px;}
	}
	.slidergallery{
		.slick-arrow{margin-top: -60px;}
	}
	.program-section{padding: 60px 0px 80px;}
	.ttl-mailer{
		h3{font-size: 25px;}
	}
	.partner-link{
		a{
			img{max-width: 120px;}
		}
	}
}
@media all and (max-width: 740px) {
	.health-detail{
		h2{font-size: 30px;line-height: 38px;margin-bottom: 20px;}
	}
	.box-slidermagazine{
		padding: 45px 0px 80px;
		h3{font-size: 20px;margin-bottom: 20px;}
		.wrapper{padding-left: 60px;}
	}
	.slidermagazinedetail{
		.list{width: 177px;margin-right: 20px;}
		.slick-arrow{
			width: 39px;height: 39px;background-size: 50%;
			left: -50px;
			&.slick-prev{margin-top: -50px;}
		}
	}
	.event-list{
		margin: 0px 0px 15px;
		.list{
			flex-basis: 100%;max-width: 100%;margin-left: 0px;margin-right: 0px;
			figcaption{
				padding: 15px;
				h5{font-size: 14px;}
			}
		}
	}
	.past-issues{
		h3{font-size: 20px;margin-bottom: 20px;}
	}
	.slider-promo{
		.slick-arrow{width: 35px;height: 35px;margin-top: -18px;}
	}
	.promobox{
		h3{font-size: 20px;}
	}
	.pop-gallery-list{
		.numslide{
			margin-top: 0px;top: 120px;font-size: 13px;
			padding:10px 10px 40px;width: 100px;
			.num{font-size: 22px;}
		}
	}
	.slidergallery{
		.slick-arrow{
			margin-top: 0px;top: 30% !important;width: 45px;height: 45px;
			&.slick-prev{left: 20px;margin-top: -1px;}
			&.slick-next{right: 20px;}
		}
	}
	.program-section{
		.ttl-program{width: 100%;margin-bottom: 30px;}
	}
	.ttl-mailer{
		h3{font-size: 20px;}
	}
	.partner-link{
		a{
			img{max-width: 100px;max-height: 40px;}
		}
	}
	.potrait-lg{
		&.fximg{
			figcaption{padding-top: 50px;}
		}
	}
	.pop-gallery-list {
		.slidergallery {
			.slick-arrow {
				top: 150px;
			}
		}
	}
	#popup_gallery .in-popup .overflow-box{
	    margin: 0 -0px;
		padding: 0 0px;
	}
}
@media all and (max-width: 740px) {
	.sliderhome{
		.list{
			figure{height: calc(100vh - 111px);min-height: 340px;}
			.desc{
				width: auto;padding: 0px 50px;
				h2{
					font-size: 33px;margin-bottom: 5px;
					span{font-size: 31px;}
				}
				h3{font-size: 28px;margin-bottom: 20px;}
			}
		}
		.slick-arrow{
			&.slick-prev{left: 10px;}
			&.slick-next{right: 10px;}
		}
	}
	.homecontent{
		padding: 55px 20px;
		.vegeright{width: 170px;height: 210px;right: -40px;margin-top: 40px;}
		.in-homecontent{
			> section{
				> h2{left: 22px;font-size: 40px;}

				&.facilities-home,&.news-home,&.magazine-home{
					.wrapper{width: 100%;padding-left: 25px;padding-right: 25px;}
				}
				&.about-home{
					padding: 54px 25px;
					.box-aside{
						flex-wrap: wrap;text-align: center;
						h3{margin-bottom: 20px;}
						.left-side,.right-side{width: 100%;}
						.left-side{
							margin-bottom: 32px;
							img{max-height: 50px;}
						}
					}
				}
				&.facilities-home{
					&:before{background-size:150px;}
					> h2{margin-top: 50px;}
					h3{font-size: 22px;margin-bottom: 5px;}
					.box-slide-facilities{padding-top: 100px;}
					.slider-facilities{
						margin-top: 0px;
						.slick-list{margin-right: 0px;}
					}
					.box-link-facilities{padding-top: 30px;}
				}
				&.news-home{
					.vegeleft{width: 300px;left: -110px;margin-top: -100px;}
					.labu{background-size: 120px;bottom: -50px;}
				}
				&.magazine-home{
					padding-top: 70px;
					> h2{left: 25px;}
					.left-magazine{
						figure{
							img{
								@include position(absolute, 0px);width: 100%;
								height: 100%;object-fit: cover;
							}
							figcaption{
								@include position(relative, null auto auto);
								padding: 200px 22px 55px;text-align: center;
								h3{font-size: 24px;line-height: 30px;margin-bottom: 10px;}
								p{font-size: 14px;line-height: 22px;}
							}
						}
					}
					.right-magazine{
						.slidermagazine{margin-right: -25px;}
					}
				}
			}
		}
	}
	.box-advantages{
		.list{
			width: 100%;

			&:first-child{
				figure{
					@include borderRadius(14px 14px 0px 0px);
					&:before,img{@include borderRadius(14px 14px 0px 0px);}
				}
			}
			&:last-child{
				figure{
					@include borderRadius(0px 0px 14px 14px);
					&:before,img{@include borderRadius(0px 0px 14px 14px);}
				}
			}
		}
	}
	.slider-facilities{
		.list{
			margin: 0px;
			.desc-det{display: none;}
			&.slick-current{
				figure{
					@include transform(scale(1));
					/*img{@include borderRadius(0px);}*/
				}
				figcaption{opacity: 1;bottom: 24px;}
			}
		}
		.overhide{
			.slick-list{margin-right: 0px;}
		}
		.slick-arrow{
			width: 40px;height: 40px;
			&:after{background-size:20px;}
			&.slick-prev{left: -20px;}
			&.slick-next{right: -20px;}
		}
	}
	.box-link-facilities{
		.thumb-img{
			flex-wrap: wrap;
			.list{
				width: 100%;
				&:nth-child(n+2){display: none;}
			}
		}
		.wrapper{
			.lg-link{
				figcaption{padding: 0px 20px 25px;}
				.facilities-search{
					font-size: 16px;
					&:before{background-size:60%;}
				}
			}
			.thumb-link{
				.side-title{left: 25px;right: 25px;}
			}
		}
	}
	.slidermagazine{
		.list{width: 208px;}
		.slick-arrow{
			&.slick-next{right: -10px;}
		}
	}
}
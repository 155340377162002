.story{
	padding-bottom: 117px;overflow: hidden;
	.wrapper{
		width: 1440px;position: relative;display: flex;justify-content: space-between;
		align-items:flex-start;
	}
	.left-story{width: 605px;position: relative;}
	.right-story{width: 570px;}
	.numslide{
		position: absolute;bottom: 140px;left: 42px;color: $white;font-size: 20px;
		font-family: $eczar;
		.num{font-size: 31px;font-weight: 700;}
	}
}

.sliderstory{
	position: relative;padding: 78px 0px 0px 135px;@include boxSizing(border-box);
	&:before{
		content:"";position: absolute;top: 0px;left: 0px;bottom: 43px;right: 70px;background: #7CA557;
		@include borderRadius(10px);z-index: -1;
	}
	.list{
		position: relative;
		img{width: 470px;@include borderRadius(10px);position: relative;z-index: 2;}
	}

	.slick-arrow{
		position: absolute;bottom: 110px;z-index: 1;border: none;font-size: 0px;color: transparent;padding: 0px;
		width: 30px;height: 16px;background: url('../images/material/arr-right-md.svg')no-repeat center;
		cursor: pointer;
		&.slick-prev{@include transform(rotate(180deg));left: 29px;}
		&.slick-next{left: 81px;}
	}
}

.slidercaptionstory{
	.side-icon{margin-bottom: 27px;}
}

.result-map{
	padding-top: 54px;
	p{font-size: 16px;color: #7C7968;line-height: 24px;}
}

.side-map{
	display: flex;justify-content: space-between;align-items: stretch;
	.left-map,.right-map{width: 48%;}
	.left-map{		
		.list{
			@include borderRadius(10px);padding: 22px 25px;background: #FAFAFA;margin-bottom: 5px;
			cursor: pointer;position: relative;@include transition(.3s background ease-out);
			&:before{
				content:"";position: absolute;top: 50%;left: 100%;margin-top: -8px;opacity: 0;
				border-top: 8px solid transparent;border-bottom: 8px solid transparent;border-left: 18px solid #8DB766;
			}
			span{display: block;font-size: 13px;color: #7C7968;text-transform: uppercase;line-height: 20px;}
			h5{font-size: 18px;font-weight: 700;font-family: $sfui;color: #292929;line-height: 24px;margin-bottom: 7px;}
			p{font-size: 13px;line-height: 20px;margin-bottom: 0px;}
			.drop-map,.map-src{display: none;}

			&.active{
				background: #8DB766;color: $white;
				&:before{opacity:1;}
				span,h5,p,a{color: $white;}
				.drop-map{display: block;}
			}
		}
	}
	.right-map{
		height: 450px;
		iframe{
			width: 100% !important; 
			height: 100% !important;  
			@include borderRadius(10px);
		}
	}
}

.certification-lg{
	.list{
		display: none;
		&.active{display: block;}
	}
}

.address-list{
	display: flex;flex-wrap: wrap;margin:0px -15px 30px;
	.list{
		color: #7C7968;margin-bottom: 20px;padding-bottom: 20px;
		flex: 1 0 33.333333%;
		max-width: 33.333333%;
		padding: 0px 15px;
		@include boxSizing(border-box);
		h5{color: #7C7968;font-size: 18px;margin-bottom: 16px;}
		p,a{font-size: 14px;line-height: 24px;margin-bottom: 0px;color: #7C7968;}
	}
}
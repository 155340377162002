/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 14px;
    font-family: $sfui; 
    position: relative;
    overflow-x: hidden;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;    
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: $sfui;
}
button{font-family: $sfui;}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 58px;font-family: $sunset;font-weight: 700; }
h3 { font-size: 39px;font-family: $sunset;font-weight: 700; }
h4 { font-size: 25px;font-family: $sunset;font-weight: 700;line-height: 36px; } 
h5 { font-size: 16px;font-family: $sunset;font-weight: 700;line-height: 1.3;} 
h6 { font-size: 14px; }

/* structure 
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}

section { position: relative; }
.wrapper,.wrap-mid,.wrap-small{
    margin: 0px auto;word-wrap: break-word;
    position: relative; z-index: 7;
}
.wrapper {width: 1170px;}
.wrap-mid{
    width: 970px; 
    &.result {min-height: 665px;}
}
.wrap-small{width: 770px;}


/* std-content
----------------------------------------------------------------------------------------------*/
.std-content{
    h5{color: #FF6F61;margin-bottom: 10px;line-height: 24px;}
    p{font-size: 16px;line-height: 28px;color: #7C7968;}
    ul,ol{
        li{font-size: 16px;line-height: 30px;color: #7C7968;}
    }
    ul {
        margin: 0 0 30px 0;
        li {
            margin: 0px;padding: 0px 0px 0px 20px;position: relative;
            &:before{
                content:"";position: absolute;top: 11px;left: 3px;width: 5px;height: 5px;background: #7CA557;
                @include borderRadius(100%);
            }
        }  
    }
    ol { 
        margin: 0 0 30px 0;counter-reset: my-awesome-counter;
        li {
            margin: 0px;padding: 0px 0px 0px 20px;counter-increment: my-awesome-counter;position: relative;
            &:before{
                content: counter(my-awesome-counter) ".";
                position: absolute;top: 0px;left: 0px;
            }
        }  
    }
    /*table,thead,tbody,tfoot,tr,th,td{
        border: 1px solid grey;
    }
    table{
        th,td{padding: 10px;line-height: 1.3;}
        th{font-weight: bold;}
    }*/
    table{
        width: 100%;
        th,td{
            color: #7C7968;text-align: center;background: #FAFAFA;padding: 20px 17px;font-size: 14px;
            line-height: 18px;
            &:first-child{@include borderRadius(10px 0px 0px 10px);}
            &:last-child{@include borderRadius(0px 10px 10px 0px);}
        }
        thead{
            tr{
                th,td{
                    background: #7CA557;color: $white;padding: 22px 17px;font-weight: 600;
                    span{display: block;font-weight: 500;font-style: italic;font-size: 13px;}
                }
            }
        }
        tbody,tfoot{
            th,td{
                &:first-child{text-align: left;}
                &:last-child{text-align: right;}
            }
            tr{border-top: 5px solid $white;}
        }
        tfoot{
            tr{
                th,td{background: #B7B4A2;color: $white;font-weight: 600;}
            }
        }
    }

    &.pnomarg{
        p{margin-bottom: 0px;}
    }
    &.hgreen{
        h4{color: #7CA557;font-family: $sfui;}
        h5{color: #7CA557;font-family: $sfui;font-size: 20px;line-height: 1.3;}
    }
}


/* header
----------------------------------------------------------------------------------------------*/
header{
    position: fixed;top: 0px;left: 0px;right: 0px;z-index: 1000;background: $white;
    @include boxShadow(0px 3px 10px 0px rgba(0,0,0,.05));
    .head-top{
        text-align: center;padding: 26px 0px;position: relative;overflow: hidden;
        &:before,&:after{content:"";position: absolute;}
        &:before{
            background: url('../images/material/bg-left-head.png') no-repeat center;width: 395px;height: 390px;
            left: 80px;top: -175px;
        }
        &:after{
            background: url('../images/material/bg-right-head.png') no-repeat center;width: 248px;height: 232px;
            right: 130px;top: 0px;
        }
    }
    .head-bottom{
        background: #F9FAFA;
        .burger-button{
            display: none;
            cursor: pointer; 
        }
        ul{
            float: left;
            > li{
                display: inline-block;margin-right: 40px;text-transform: uppercase;position: relative;
                a{color: #7C7968;display: inline-block;}
                > a{line-height: 60px;}
                > ul{
                    position: absolute;top: 80%;left: 50%;z-index: 10;width: 220px;margin-left: -110px;
                    @include borderRadius(8px);padding-top: 20px;opacity: 0;visibility: hidden;
                    @include transition(.2s all ease-out);
                    &:before{
                        content:"";position: absolute;top: 3px;left: 50%;z-index: -1;margin-left: -11px;
                        border-left: 11px solid transparent;border-right: 11px solid transparent;border-bottom: 19px solid $white;
                    }
                    &:after{
                        content:"";position: absolute;top: 20px;left: 0px;right: 0px;bottom: 0px;background: $white;
                        @include borderRadius(8px);z-index: -2;@include boxShadow(0px 0px 50px 0px rgba(0,0,0,0.22));
                    }
                    > li{
                        border-bottom: 1px solid rgba(227,227,227,.8);display: block;
                        margin-right: 0px;text-transform: none;
                        > a{line-height: 1.3;display: block;padding: 18px 20px;}
                        &:hover{
                            background: #698D48;
                            a{color: $white;}
                        }
                        &:first-child{@include borderRadius(8px 8px 0px 0px);}
                        &:last-child{border-bottom: none;@include borderRadius(0px 0px 8px 8px);}
                    }
                }

                &:hover{
                    > a{color: #1F5B29;}
                    > ul{opacity: 1;visibility: visible;top: 100%;}
                }
                &.active{
                    > a{color: #1F5B29;font-weight: 700;}
                }
                &:last-child{margin-right: 0px;}
                &.bottom-mobile {
                    display: none;
                }
            }
        }
        .lang-head,.search-head,.shopnow{display: inline-block;vertical-align: top;}
        .lang-head{
            padding: 0px 17px;text-transform: uppercase;border-left:1px solid rgba(124,121,104,.15);
            border-right:1px solid rgba(124,121,104,.15);line-height: 60px;
            a{
                color: #7C7968;
                &:hover{color: #1F5B29;}
                &.active{color: #1F5B29;font-weight: 700;}
            }
        }
        .search-head{
            position: relative;
            .btn-search{
                display: inline-block;width: 60px;height: 60px;background: url('../images/material/ic-search.svg')no-repeat center;
                cursor: pointer;vertical-align: top;
            }
            .drop-search{
                padding-top: 20px;display: none;
                @include position(absolute, 100% 0px null null);
                &:before{
                    content:"";
                    z-index: -1;
                    border-left: 11px solid transparent;
                    border-right: 11px solid transparent;
                    border-bottom: 19px solid $white;
                    z-index: 1;
                    @include position(absolute, 2px 20px null null);
                }
                input{
                    border: none;
                    background: $white;
                    font-size: 14px;
                    height: 50px;
                    width: 300px;
                    padding: 0px 20px;
                    @include borderRadius(10px);
                    @include boxShadow(0px 0px 50px 0px rgba(0,0,0,.22));
                }
            }
        }
        .head-right{float: right;}
    }
}

/* footer
----------------------------------------------------------------------------------------------*/
footer{
    display: table;align-content:stretch;background: #7C7968;width: 100%;font-family: $opensans;
    a{color: $white;}
    nav{
        a{
            display: inline-block;vertical-align: middle;margin-right: 60px;text-transform: uppercase;
            font-weight: 700;
            &:last-child{margin-right:0px;}
        }
    }
    .left-foot,.right-foot{display: table-cell;vertical-align: middle;}
    .left-foot{
        padding: 33px 103px 24px;color: $white;display: flex;justify-content: space-between;
        align-items: center;
        .copyright{font-size: 13px;line-height: 25px;}
    }
    .right-foot{
        background: rgba(41,41,41,.33);text-align: center;
        a{
            display: inline-block;vertical-align: middle;margin-right: 60px;text-transform: uppercase;
            font-weight: 700;
            &:last-child{margin-right:0px;}
        }
    }
}



/* pages
----------------------------------------------------------------------------------------------*/
@import "pages/general";
@import "pages/home";
@import "pages/about";
@import "pages/corporate";
@import "pages/product";
@import "pages/career";
@import "pages/promo";
@import "pages/tips";
@import "pages/trust";

/* pages responsive desktop
----------------------------------------------------------------------------------------------*/
@import "pages_desktop/general";
@import "pages_desktop/home";

/* pages responsive tablet
----------------------------------------------------------------------------------------------*/
@import "pages_tablet/general";
@import "pages_tablet/home";
@import "pages_tablet/about";
@import "pages_tablet/corporate";
@import "pages_tablet/product";
@import "pages_tablet/promo";
@import "pages_tablet/tips";
@import "pages_tablet/trust";
@import "pages_tablet/career";

/* pages responsive mobile
----------------------------------------------------------------------------------------------*/
@import "pages_mobile/general";
@import "pages_mobile/home";
@import "pages_mobile/about";
@import "pages_mobile/corporate";
@import "pages_mobile/product";
@import "pages_mobile/promo";
@import "pages_mobile/trust";
@import "pages_mobile/career";
@media all and (max-width: 1300px) {
	.sliderhome{
		.list{
			figure{
				height: calc(100vh - 130px);
				img{max-width: none;min-width: 100%;height: 100%;width: auto;}
			}
			.desc{
				h2{
					font-size: 54px;
					span{font-size: 52px;}
				}
				h3{font-size: 47px;}
			}
		}

		.slick-arrow{width: 50px;height: 50px;}
	}

	.homecontent{
		padding-left: 60px;padding-right: 60px;
		@include boxSizing(border-box);
		.wrapper{padding: 0px;}
		.vegeright{
			background-size: 100%;width: 250px;height: 350px;
			right: -50px;margin-top: 70px;
		}
		.in-homecontent{
			width: 100%;
			.box-aside{
				.submenu-aside{display: none;}
			}
			> section{
				> h2{font-size: 70px;left: 40px;}

				&.about-home{
					padding: 78px 84px;
					&:before{background-size: 230px;background-position: -50px top;}
				}
				&.facilities-home{
					&:before{background-size: 200px;}
					> h2{left: -10px;}
					h3{font-size: 28px;}
					.box-slide-facilities{
						padding: 140px 0px 60px;
					}
					.slider-facilities{
						.overhide{overflow: hidden;}
						.slick-list{
							margin-right: -187px;
						}
					}
					.side-title{
						h3{margin-bottom: 10px;}
					}
				}
				&.news-home{
					padding-top: 97px;
					.vegeleft{
						background-size: 100%;width: 480px;height: 500px;left: -150px;
						margin-top: -150px;
					}
					.labu{background-size: 160px;width: 110px;}
				}
				&.magazine-home{
					padding-top: 80px;
					.wrapper{
						display: flex;justify-content: space-between;
						align-items: flex-end;
						&:before,&:after{display: none;}
					}
					.left-magazine{width: 60%;}
					.right-magazine{
						width: 35%;padding-left: 0px;
						.slidermagazine{width: auto;margin-right: -45px;}
					}
				}
			}
		}
	}

	.slider-facilities{
		.list{
			h5{font-size: 16px;}
			p{font-size: 14px;line-height: 20px;}
			.desc-det{left: -35px;right: -35px;}
		}
		.overhide{
			overflow: hidden;
			.slick-list{margin-right: -178px;}
		}
		.slick-arrow{
			width: 45px;height: 45px;
			&:after{background-size:25px;}
		}
	}

	.box-aside{
		margin-bottom: 50px;
		p{font-size: 14px;line-height: 30px;margin-bottom: 30px;}
	}

	.box-advantages{
		.list{
			h3{font-size: 22px;}
			p{font-size: 14px;line-height: 20px;}
		}
	}

	.box-link-facilities{
		.thumb-img{
			.list{
				width: 100%;
				figcaption{
					padding: 10px 20px;
					p{font-size: 13px;}
				}
				&:nth-child(n+2){display: none;}
			}
		}
		.wrapper{
			.lg-link{width: 64%;}
			.thumb-link{padding-left: 22px;}
		}
	}
	.slidermagazine{
		.list{
			width: 221px;
			h5{font-size: 13px;line-height: 1.4;}
			figcaption{padding: 15px 20px;}
		}
		.slick-arrow{
			&.slick-next{right: -25px;}
		}
	}
}

@media all and (max-width: 1020px) {
	.sliderhome{
		.list{
			.desc{
				h2{
					font-size: 48px;
					span{font-size: 46px;}
				}
				h3{font-size: 42px;}
			}
		}
	}
	.homecontent{
		.vegeright{width: 200px;height: 300px;}
		.in-homecontent{
			> section{
				> h2{font-size: 50px;}
				&.facilities-home,&.news-home,&.magazine-home{
					.wrapper{width: 83%;}
				}
				&.about-home{
					padding: 64px 50px;
					&:before{background-size:170px;}
				}
				&.facilities-home{
					.slider-facilities{
						margin-top: -30px;
						.slick-list{margin-right: -180px;}
					}
					.box-slide-facilities{padding-top: 120px;padding-bottom: 40px;}
					.box-link-facilities{padding-bottom: 81px;padding-top: 50px;}
				}
				&.news-home{
					.vegeleft{width: 420px;}
				}
				&.magazine-home{
					padding-bottom: 86px;
					> h2{left: 80px;}
					.wrapper{flex-wrap: wrap;}
					.left-magazine,.right-magazine{width: 100%;}
					.left-magazine{margin-bottom: 51px;}
					.right-magazine{
						.slidermagazine{margin-right: -30px}
					}
				}
			}
		}
	}
	.slider-facilities{
		.overhide{
			.slick-list{margin-right: -214px;}
		}
	}
	.box-aside{
		p{line-height: 24px;margin-bottom: 20px;}
		.left-side{width: 35%;}
		.right-side{width: 60%;}
	}
	.box-advantages{
		.list{
			h3{font-size: 20px;}
			p{display: none;}
		}
	}
	.box-link-facilities{
		.wrapper{
			position: relative;padding-top: 90px;
			.lg-link, .thumb-link{display: block;width: 100%;}
			.lg-link{margin-bottom: 33px;}
			.thumb-link{
				padding-left: 0px;
				.side-title{@include position(absolute, 0px 0px null);}
			}
		}
		.thumb-img{
			.list{
				display: block;width: 47%;
				&:nth-child(n+2){display: block;}
			}
		}
	}
	.slidermagazine{
		.slick-arrow{
			width: 40px;height: 40px;
			&:after{background-size:50%;}
			&.slick-next{right: 10px;}
		}
	}
}
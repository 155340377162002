@media all and (max-width: 740px) {
	.story{
		padding-bottom: 60px;
		.wrapper{flex-wrap: wrap;}
		.left-story,.right-story{
			flex: 1 0 100%;max-width: 100%;@include boxSizing(border-box);
		}
		.left-story{padding-right: 40px;margin-bottom: 38px;}
		.right-story{padding: 0px 30px;}
	}
	.side-map{
		flex-wrap: wrap;
		.left-map,.right-map{width: 100%;}
		.left-map{
			order: 2;
			.list{
				&:before{display: none;}
			}
		}
		.right-map{
			order: 1;margin-bottom: 20px;
			height: 400px;
			/*iframe{height: 400px;}*/
		}
	}
	.address-list{
		.list{
			flex-basis: 100%;max-width: 100%;margin-bottom: 30px;
			h5{margin-bottom: 10px;}
		}
	}
}
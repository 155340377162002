@media all and (max-width: 1300px) {
	.story{
		.wrapper{
			padding: 0px;width: 100%;
			&:after{display: none;}
		}
		.left-story,.right-story{
			@include boxSizing(border-box);
		}
		.left-story{flex: 1 0 50%;max-width: 50%;}
		.right-story{
			padding-right: 60px;flex: 1 0 45%;max-width: 45%;
		}
	}
	.side-map{
		.left-map{
			width: 45%;
			.list{
				h5{font-size: 16px;}
			}
		}
		.right-map{
			width: 50%;
			iframe{width: 100%;height: 100%;}
		}
	}
}

@media all and (max-width: 1020px) {
	.sliderstory{
		padding: 60px 0px 0px 86px;
		.slick-arrow{
			background-size: 100%;width: 23px;bottom: 80px;
			&.slick-prev{left: 18px;}
			&.slick-next{left: 52px;}
		}
	}
	.story{
		.numslide{
			font-size: 13px;left:24px;bottom: 110px;
			.num{font-size: 22px;}
		}
	}
	.side-map{
		.left-map{
			.list{
				h5{font-size: 15px;line-height: 1.3;}
			}
		}
	}
	.address-list{
		.list{
			h5{font-size: 16px;}
		}
	}
}
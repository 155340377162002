@media all and (max-width: 1300px) {
	.product-list{
		.list{
			figure{width: 45%;}
			figcaption{width: 50%;}
		}
	}
}

@media all and (max-width: 1020px) {
	.box-product{padding: 70px 0px;}
}
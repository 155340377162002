.sliderhome{
	.list{
		position: relative;
		figure{
			height: calc(100vh - 160px);overflow: hidden;
			img{width: 100%;}
		}
		.desc{
			position: absolute;top: 50%;left: 0px;right: 0px;width: 600px;margin: 0px auto;
			@include transform(translateY(-50%));text-align: center;
			h2,h3{font-family: $sunset;}
			h2{
				font-size: 66px;color: #8AB661;font-weight: 700;margin-bottom: 10px;
				span{font-size: 64px;@include transform(rotate(-29deg));display: inline-block;color: #B7B4A2;}
			}
			h3{font-size: 58px;font-weight: 700;color: #7C7968;margin-bottom: 24px;}
		}
	}

	.slick-arrow{
		position: absolute;top: 50%;z-index: 1;border: none;font-size: 0px;color: transparent;padding: 0px;
		background:rgba(255,255,255,.7);width: 66px;height: 66px;
		@include borderRadius(100%);margin-top: -33px;cursor: pointer;
		&:before{
			content:"";position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;@include borderRadius(100%);opacity: 0;
			background: rgba(255,255,255,1);@include transform(scale(0));z-index: -1;@include transition(.2s all ease-out);
		}
		&:after{
			content:"";position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;@include borderRadius(100%);
			background: url('../images/material/arrow-right.svg') no-repeat center;
		}
		&.slick-prev{left: 34px;}
		&.slick-next{right: 34px;@include transform(rotate(180deg));}

		&:hover{
			&:before{@include transform(scale(1));opacity: 1;}
		}
	}
}

.homecontent{
	position: relative;width: 100%;padding: 88px 0px 117px;overflow: hidden;
	.vegeright{
		position: absolute;margin-top: 30px;right: -80px;width: 356px;height: 434px;
		background: url('../images/material/vegeright.png')no-repeat top right;
	}
	.wrapper{width: 1200px;}
	.in-homecontent{
		position: relative;width: 1401px;margin: 0px auto;
		&:before{
			content:"";position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;z-index: -1;
			background: url('../images/material/paper_fibers.png')repeat top left;
		}
	
		>section{
			> h2{position: absolute;margin-top: 0px;left: -24px;font-family: $sunset;font-weight: 700;color: #EFEFEF;font-size: 120px;}

			&.about-home{
				padding: 126px 100px 156px;position: relative;
				&:before{
					content:"";position: absolute;bottom: 0px;left: 0px;width: 253px;height: 206px;
					background: url('../images/material/bg-advleft.png')no-repeat top right;
				}
			}
			&.facilities-home{
				@extend .gra-green;color: $white;z-index: 1;
				&:before{
					content:"";position: absolute;top: 0px;right: 0px;width: 261px;height: 354px;z-index: -1;
					background: url('../images/material/bg-top-facilities.png')no-repeat top right;
				}
				&:after{
					content:"";position: absolute;bottom: 0px;left: 0px;right: 0px;top: 0px;z-index: -1;
					background: url('../images/material/bg-bottom-facilities.png')no-repeat bottom left;background-size: 100%;
				}
				> h2{color: #3D7538;margin-top: 80px;}
				h3{font-size: 34px;margin-bottom: 16px;}
				.slider-facilities{margin-top: -77px;}
				.box-slide-facilities{
					border-bottom: 1px solid rgba(0,0,0,0.15);padding:203px 0px 62px;
					h3{width: 500px;max-width: 100%;}
				}
				.box-link-facilities{
					padding: 69px 0px 160px;
				}
				.side-title{
					h3{margin-bottom: 0px;}
				}
			}
			&.news-home{
				padding-top: 131px;padding-bottom: 122px;
				> h2{margin-top: 60px;left: auto;right: -20px;}
				h3{margin-bottom: 16px;}
				.labu{
					width: 140px;height: 256px;background: url('../images/material/labu.png')no-repeat left center;
					position: absolute;bottom: -120px;right: 0px;
				}
				.vegeleft{
					width: 610px;height: 686px;position: absolute;margin-top: -233px;left: -245px;z-index: 2;
					background: url('../images/material/vegeleft.png')no-repeat left top;
				}
			}
			&.magazine-home{
				padding-top: 135px;padding-bottom: 100px;
				> h2{left: 114px;}
				.wrapper{display: table;}
				.left-magazine,.right-magazine{display: table-cell;vertical-align: bottom;}
				.left-magazine{
					width: 540px;position: relative;
					figure{
						position: relative;@include borderRadius(10px);@include boxShadow(0px 20px 99px 0px rgba(0,0,0,0.06));
						overflow: hidden;
						&:before{
							content:"";@extend .gra-fullblackwhite;position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;
							opacity: .6;@include borderRadius(10px);z-index: 1;
						}
						img{@include borderRadius(10px);@include transition(.2s all ease-out);width: 100%;}
						figcaption{
							position: absolute;bottom: 48px;left: 50px;right: 50px;color: $white;z-index: 2;
							@include transition(.2s all ease-out);
							h3{font-size: 32px;margin-bottom: 15px;}
							p{
								font-size: 16px;line-height: 25px;color: #FAF9F0;margin-bottom: 0px;
								span{font-weight: 700;color: #D3141A;}
							}
						}

						&:hover{
							/*img{@include transform(scale(1.1));}*/
							img{filter: blur(3px);-webkit-filter: blur(3px);}
							figcaption{bottom: 55px;}
						}
					}
				}
				.right-magazine{
					padding-left: 65px;
					.slidermagazine{margin-right: -100px;width: 698px;}
				}
			}
		}
	}
}

.box-aside{
	display: flex;justify-content: space-between;margin-bottom: 72px;
	h3{margin-bottom: 32px;color: #4D4C44;}
	p{font-size: 16px;line-height: 34px;color: #7C7968;margin-bottom: 45px;}
	.left-side{width: 353px;}
	.right-side{width: 673px;}
}

.box-advantages{
	display: flex;flex-wrap:wrap;
	.list{
		width: 33.333333333333%;position: relative;text-align: center;@include boxShadow(0px 20px 99px 0px rgba(0,0,0,0.06));
		display: block;
		h3{opacity: .9;margin-bottom: 10px;}
		p{
			color: #FAF9F0;font-size: 16px;line-height: 25px;max-height: 0px;overflow: hidden;
			@include transition(.3s all ease-out);margin-bottom: 0px;
		}
		figure{
			position: relative;overflow: hidden;
			&:before{
				content:"";position: absolute;top: 0px;left: 0px;bottom: 0px;right: 0px;background: rgba(0,0,0,.3);
				z-index: 1;
			}
			img{@include transition(.3s all ease-out);}
		}
		figcaption{
			position: absolute;top: 50%;left: 0px;right: 0px;color: $white;@include transform(translateY(-50%));
			padding: 0px 30px;z-index: 1;
		}

		&:hover{
			p{max-height: 100px;}
			figure{
				img{filter: blur(3px);-webkit-filter: blur(3px);}
			}
		}

		&:first-child{
			figure{
				img,&:before{@include borderRadius(14px 0px 0px 14px);}

			}
		}
		&:last-child{
			figure{
				img,&:before{@include borderRadius(0px 14px 14px 0px);}
			}
		}
	}
}

.slider-facilities{
	.list{
		position: relative;margin: 77px 0px;display: block;color: $white;
		h5{font-size: 18px;font-weight: 500;margin-bottom: 13px;font-family: $sfui;}
		p{font-size: 16px;line-height: 25px;}
		figure{
			position: relative;@include transition(.2s all ease-out);overflow: hidden;
			&:before{
				content:"";position: absolute;bottom: 0px;left: 0px;right: 0px;height: 100px;
				@extend .gra-transblack;@include transition(.2s height ease-out);
			}
			img{width: 100%;@include transition(.2s all ease-out);}
		}
		figcaption{
			position: absolute;bottom: 24px;left: 27px;right: 27px;@include transition(.2s all ease-out);
		}
		.desc-det{
			position: absolute;bottom: -45px;left: 0;right: -45px;opacity: 0;@include transition(.2s all ease-out);
		}

		&.slick-current{
			z-index: 2;
			figure{
				@include transform(scale(1.5));
				&:before{@include borderRadius(10px);height: 150px;}
				img{@include borderRadius(10px);}
			}
			figcaption{opacity: 0;bottom: 50px;}
			.desc-det{
				bottom: -35px;opacity: 1;
				left: -45px;right: -45px;
			}
		}

		&:hover{
			figure{
				&:before{height: 70%;}
			}
		}
	}

	.slick-arrow{
		position: absolute;top: 50%;font-size: 0px;color: transparent;padding:0px;border: none;
		width: 50px;height: 50px;@include borderRadius(100%);z-index: 1;margin-top: -25px;
		background:rgba(255, 111, 97, .7);cursor: pointer;
		&:before{
			content:"";@include position(absolute, 0px 0px 0px 0px);margin: auto;@include borderRadius(100%);
			background: #FF6F61;@include transform(scale(.5));z-index: -1;opacity: 0;
			@include transition(.2s all ease-out);
		}
		&:after{
			content:"_corporate.scss";@include position(absolute, 0px 0px 0px 0px);
			background:url('../images/material/arr-long.svg')no-repeat center;
		}
		&.slick-prev{left:-28px;}
		&.slick-next{right:-28px;@include transform(rotate(180deg));}
		&:hover{
			&:before{opacity:1;@include transform(scale(1));}
		}
	}
	&.mg_auto {
		.slick-list {
			/*padding: 0 220px !important;   */
			.slick-track {
				transform: translate3d(226px, 0px, 0px) !important; 
			}
		}
	}
}

.box-link-facilities{
	.wrapper{
		display: table;
		.lg-link,.thumb-link{display: table-cell;vertical-align: bottom;}
		.lg-link{
			width: 560px;overflow: hidden;
			a{color: $white;}
			h3{font-size: 29px;color: #769C53;margin-bottom: 15px;}
			figure{
				position: relative;overflow: hidden;@include borderRadius(10px 10px 0px 0px);
				&:before{
					content:"";position: absolute;bottom: 0px;left: 0px;right: 0px;height: 200px;z-index: 1;
					@extend .gra-blackwhite;
				}
				img{@include borderRadius(10px);@include transition(.2s all ease-out);width: 100%;}
			}
			figcaption{
				padding: 0px 48px 52px;background: #292929;@include borderRadius(0px 0px 10px 10px);
			}
			.facilities-search{
				border-top: 1px solid rgba(112,112,112,.2);padding: 10px 40px 10px 0px;font-size: 23px;position: relative;
				&:before{
					content:"";position: absolute;top: 0px;right: 0px;bottom: 0px;width: 36px;
					background: url('../images/material/ic-search-white.svg')no-repeat center;
				}
			}

			&:hover{
				figure{
					img{@include transform(scale(1.1));}
				}
			}
		}
		.thumb-link{padding-left: 45px;}
	}
}

.slidermagazine{
	position: relative;
	.slick-track{display: flex;}
	.list{
		display: block;margin-right: 30px;width: 270px;background: $white;@include borderRadius(10px);
		height: auto;
		figure{
			position: relative;overflow: hidden;@include borderRadius(10px 10px 0px 0px);
			&:before{
				content:"";position: absolute;top: 0px;left: 0px;bottom: 0px;right: 0px;background: rgba(0,0,0,0.5);
				opacity: 0;@include transition(.2s all ease-out);z-index: 1;
			}
			img{
				width: 100%; 
				@include transition(.2s all ease-out);
			}
		}
		figcaption{padding: 20px;color: #4D4C44;}

		&:hover{
			figure{
				&:before{opacity:1;}
				img{@include transform(scale(1.1));filter: blur(3px);-webkit-filter: blur(2px);}
			}
		}
	}
	.slick-arrow{
		position: absolute;top: 50%;border: none;padding: 0px;font-size: 0px;color: transparent;width: 50px;
		height: 50px;background:rgba(255, 111, 97, .7);cursor: pointer;
		@include borderRadius(100%);z-index: 1;margin-top: -50px;
		&:before{
			content:"";@include position(absolute, 0px 0px 0px 0px);margin: auto;@include borderRadius(100%);
			background: #FF6F61;@include transform(scale(.5));z-index: -1;opacity: 0;
			@include transition(.2s all ease-out);
		}
		&:after{
			content:"";@include position(absolute, 0px 0px 0px 0px);
			background:url('../images/material/arr-long.svg')no-repeat center;
		}
		&.slick-prev{display: none !important;}
		&.slick-next{@include transform(rotate(180deg));right: 110px;}&:hover{
			&:before{opacity:1;@include transform(scale(1));}
		}
	}
}
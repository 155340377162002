.detail-page{
	h4{color: #7CA557;margin-bottom: 30px;}
	h5{color: #7C7968;font-family: $sfui;}
	p{margin-bottom: 30px;}
	img{margin-bottom: 40px;display: inline-block;}
	.ttl-detail{
		span{display: block;color: #698D48;font-size: 13px;font-weight: 500;margin-bottom: 9px;}
		h2{font-size: 49px;line-height: 60px;color: #4D4C44;margin-bottom: 50px;}
	}
	.foot-detailpage{
		display: flex;justify-content: space-between;border-top: 1px solid #EEEEEE;padding-top: 22px;
	}
}